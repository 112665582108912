
/*!
 *  Mailchimp template editor module form.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";

import Globals from "Class/Globals";
import { UcFirst } from "Functions";

import CheckboxField from "Components/UI/Field/CheckboxField";
import ImageField from "Components/UI/Field/ImageField";
import PaletteField from "Components/UI/Field/PaletteField";
import SelectField from "Components/UI/Field/SelectField";
import TabMenu from "Components/UI/TabMenu";
import TextareaField from "Components/UI/Field/TextareaField";
import TextField from "Components/UI/Field/TextField";
import WysiwygField from "Components/UI/Field/WysiwygField";

class TemplateEditorForm extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            tabMenues: []

        };

    }

    ExtractImageIds = ( value ) => {

        const Ids = [];

        if ( typeof value !== "object" ) {

            return Ids;

        }

        value.forEach( image => {

            const Id = typeof image === "object" ? image[0] : image;

            if ( !Id ) {

                return;

            }

            Ids.push( typeof image === "object" ? image[0] : image );

        } );

        return Ids;

    }

    Fields = ( fields, container = [], fieldValues = {} ) => {

        const { onChange } = this.props;
        const { tabMenues } = this.state;

        let TabLabels = [];
        let TabContents = [];
        let TabMenues = 0;

        const OutputTabMenu = () => {

            let Tab = tabMenues[ TabMenues ];

            container.push(

                <TabMenu 
                
                    active={ String( Tab || 0 ) }
                    id={ TabMenues }
                    items={ TabLabels }
                    key={ `tabMenu${TabMenues}` }
                    onClick={ this.OnTab }
                
                />

            );

            container.push(

                <div
                
                    key={ `tabs${TabMenues}` }
                    className="TemplateEditorModuleTabs"
                    
                >

                    { TabContents }

                </div>

            );

            TabLabels = [];
            TabContents = [];
            TabMenues++;

        }

        for ( let key in fields ) {

            let {
                
                children,
                colors,
                default: defaultValue,
                disabledIf,
                flip,
                label,
                options,
                placeholder,
                type: fieldType
                
            } = fields[ key ];

            let Disabled = false;

            if ( disabledIf ) {

                if ( disabledIf.substr( 0, 1 ) === "!" ) {

                    Disabled  = !fieldValues[ disabledIf.substr( 1 ) ];

                }

                else {

                    Disabled = !!fieldValues[ disabledIf ];

                }

            }

            if ( fieldType !== "tab" && TabLabels.length ) {

                OutputTabMenu();

            }

            switch ( fieldType ) {

                case "checkbox":

                    container.push( <CheckboxField
                    
                        checked={ !!fieldValues[ key ] || defaultValue }
                        disabled={ Disabled }
                        id={ key }
                        key={ key }
                        label={ label }
                        onChange={ onChange }
                    
                    /> );

                    break;

                case "color":

                    let Colors = colors ? [] : Globals.Colors;

                    if ( colors ) {

                        colors.forEach( slug => {

                            Colors.push( Globals.ColorsK[ slug ] || [ "transparent", UcFirst( slug ), slug ] );

                        } );

                    }

                    container.push( <PaletteField

                        disabled={ Disabled }
                        flip={ flip }
                        id={ key }
                        key={ key }
                        label={ label }
                        onChange={ onChange }
                        colors={ Colors }
                        value={ fieldValues[ key ] || defaultValue }
                    
                    /> );

                    break;

                case "group":

                    container.push(
                    
                        <div className="TemplateEditorModuleGroup" key={ key }>

                            <div className="TemplateEditorModuleGroupLabel">{ label }</div>

                            { this.Fields( children, [], fieldValues ) }

                        </div>
                    
                    );

                    break;

                case "image":

                    container.push( <ImageField
                    
                        disabled={ Disabled }
                        id={ key }
                        key={ key }
                        label={ label }
                        onChange={ this.OnImage }
                        placeholder={ placeholder }
                        value={ this.ExtractImageIds( fieldValues[ key ] ) || defaultValue }
                    
                    /> );

                    break;

                case "select":

                    container.push( <SelectField
                    
                        disabled={ Disabled }
                        flip={ flip }
                        id={ key }
                        key={ key }
                        label={ label }
                        onChange={ onChange }
                        options={ options }
                        value={ fieldValues[ key ] || defaultValue }
                    
                    /> );

                    break;

                case "tab":

                    let Tab = tabMenues[ TabMenues ];
                    let TabIndex = TabLabels.length;
                    let TabClassName = ( Tab === undefined && !TabIndex ) || Tab === TabIndex ? "TemplateEditorModuleTab Active" : "TemplateEditorModuleTab";

                    TabLabels.push( label );

                    TabContents.push(
                    
                        <div className={ TabClassName } key={ key }>

                            { this.Fields( children, [], fieldValues ) }

                        </div>
                    
                    );

                    break;

                case "textarea":

                    container.push( <TextareaField
                    
                        disabled={ Disabled }
                        id={ key }
                        key={ key }
                        label={ label }
                        onChange={ onChange }
                        onInput={ onChange }
                        placeholder={ placeholder }
                        value={ fieldValues[ key ] || defaultValue }
                    
                    /> );

                    break;

                case "wysiwyg":

                    container.push( <WysiwygField
                    
                        disabled={ Disabled }
                        id={ key }
                        key={ key }
                        label={ label }
                        onChange={ onChange }
                        onInput={ onChange }
                        placeholder={ placeholder }
                        value={ fieldValues[ key ] || defaultValue }
                    
                    /> );

                    break;

                default:

                    container.push( <TextField
                    
                        disabled={ Disabled }
                        id={ key }
                        key={ key }
                        label={ label }
                        onChange={ onChange }
                        onInput={ onChange }
                        placeholder={ placeholder }
                        value={ fieldValues[ key ] || defaultValue }
                    
                    /> );

            }

        }

        if ( TabLabels.length ) {

            OutputTabMenu();

        }

        return container;

    }

    OnImage = ( e, ids, objs, key ) => {

        const { onChange } = this.props;
        const Value = [];
        
        ids.forEach( ( id, index ) => {

            const { full_size_url } = objs[ index ] || {};

            Value.push( [ id, full_size_url || "" ] );

        } );

        onChange( e, Value, key );

    }

    OnTab = ( e, tabIndex, menuIndex ) => {

        const { tabMenues } = this.state;

        tabMenues[ menuIndex ] = parseInt( tabIndex, 10 );

        this.setState( { tabMenues } );
        
    }

    render() {

        const { active, modules, onChangeTemplate, templates } = this.props;
        const Ready = typeof modules === "object" && typeof templates === "object";
        const Templates = {};
        const Fields = [];
        let DisableTemplates = true;
        let SelectedTemplate;

        if ( !Ready || active < 0 ) {

            return "";

        }

        if ( active >= 0 ) {

            const { type, fields: values } = modules[ active ] || {};
            const { fields, name, repeatable } = templates[ type ] || {};

            if ( !repeatable && name ) {

                Templates[ type ] = name;

            }

            else if ( repeatable ) {

                DisableTemplates = false;

                for ( let key in templates ) {

                    if ( !templates[ key ].repeatable ) {

                        continue;

                    }

                    Templates[ key ] = templates[ key ].name;

                }

            }

            if ( fields ) {

                this.Fields( fields, Fields, values );

            }

            SelectedTemplate = type;

        }

        return (

            <div className="TemplateEditorModule">

                <div className="TemplateEditorModuleHead">

                    <SelectField
                
                        disabled={ DisableTemplates }
                        label="Mall"
                        onChange={ onChangeTemplate }
                        options={ Templates }
                        value={ SelectedTemplate }
                    
                    />

                </div>

                <div className="TemplateEditorModuleBody">

                    { Fields }

                </div>

            </div>

        );

    }

}

TemplateEditorForm.propTypes = {

    active: PropTypes.number,
    modules: PropTypes.oneOfType( [ PropTypes.array, PropTypes.bool ] ),
    onChange: PropTypes.func,
    onChangeTemplate: PropTypes.func,
    templates: PropTypes.oneOfType( [ PropTypes.object, PropTypes.bool ] )

};

TemplateEditorForm.defaultProps = {

    active: -1,
    modules: [],
    onChange: () => {},
    onChangeTemplate: () => {},
    templates: {}

};

export default TemplateEditorForm;