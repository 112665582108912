
import React from "react";
import { Redirect } from "react-router-dom";

import TemplateEditor from "Components/View/TemplateEditor";

class ViewTemplateNew extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            id: 0

        };

    }

    OnSave = ( id ) => {

        this.setState( { id } );

    }

    render() {

        const { id } = this.state;

        if ( id ) {

           return  <Redirect to={ `/templates/edit/${id}` } />;

        }

        return <TemplateEditor onSave={ this.OnSave } />;

    }

}

export default ViewTemplateNew;