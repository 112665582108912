
/*!
 *  Checkbox form field.
 *
 *  @prop boolean|integer checked - Whether the field is checked.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field is disabled.
 *  @prop boolean error - Whether this field has an erroneous value. 
 *  @prop string id - Field ID.
 *  @prop string label - Field label. Overrides children.
 *  @prop function onChange - Callback function.
 *  @prop string text - Text beside the radio button field.
 *  @prop string title - The checkbox title attribute.
 *  @prop mixed valueTrue - Return value when checked. Defaults to 'true'.
 *  @prop mixed valueFalse - Return value when checked. Defaults to 'false'.
 *  @prop JSX [ ...children ] - Field content.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./checkboxfield.scss";

import Icon from "Components/Layout/Icon";

class CheckboxField extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            checked: false

        };

    }

    /**
     * Set initial value.
     * 
     * @return void
     */

    componentDidMount() {

        const { checked } = this.props;

        this.setState( { checked } );

    }

    /**
     * Update value.
     * 
     * @return void
     */

    UNSAFE_componentWillReceiveProps( nextProps ) {

        const { checked } = nextProps;

        if ( checked !== this.props.checked ) {
            
            this.setState( { checked } );

        }

    }

    /**
     * Toggle value when clicked.
     * 
     * @param object e - Click event.
     * 
     * @return void
     */

    OnClick = (e) => {

        const { disabled, id, onChange, valueTrue, valueFalse } = this.props;
        let { checked } = this.state;

        if ( disabled ) {

            return;

        }

        checked = !checked;

        this.setState( { checked } );

        onChange( e, checked ? valueTrue : valueFalse, id );

    }

    /**
     * Reset to inital state.
     * 
     * @return void
     */

    Reset = () => {

        const { checked } = this.props;

        this.setState( { checked } );

    }

    /**
     * Get the field value.
     * 
     * @return mixed - The field value.
     */

    Value = () => {

        const { valueTrue, valueFalse } = this.props;
        const { checked } = this.state;

        return checked ? valueTrue : valueFalse;

    }

    render() {

        const { children, className, disabled, error, label, text, title } = this.props;
        const { checked } = this.state;
        const CA = [ "Field", "CheckboxField" ];

        // Behaviour and appearance changes depending on if props.label is set.
        if ( className ) CA.push( className );
        if ( checked ) CA.push( "Checked" );
        if ( disabled ) CA.push( "Disabled" );
        if ( error ) CA.push( "Error" );
        else CA.push( "NoLabel" );

        const CS = CA.join( " " );

        return (
            
            <div className={ CS }>

                { label ?  <label onClick={ this.OnClick }>

                    { label }

                </label> : "" }

                <div className="CheckboxFieldContent">

                    <div
                    
                        className="Input" onClick={ this.OnClick }
                        onMouseDown={ e => e.stopPropagation() }
                        title={ title || label }
                        
                    >

                        { checked ? <Icon feather="Check" /> : "" }

                    </div>

                    <span onClick={ text ? this.OnClick : () => {} }>

                        { text || children }

                    </span>

                </div>

            </div>
            
        );

    }

}

CheckboxField.propTypes = {

    checked: PropTypes.oneOfType( [ PropTypes.bool, PropTypes.number ] ),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    label: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    onChange: PropTypes.func,
    text: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    title: PropTypes.string

};

CheckboxField.defaultProps = {

    checked: false,
    className: "",
    disabled: false,
    error: false,
    id: "",
    label: "",
    onChange: () => {},
    text: "",
    title: "",
    valueTrue: true,
    valueFalse: false

};

export default CheckboxField;