
/*!
 *  WYSIWYG
 *
 *  https://github.com/zenoamaro/react-quill
 *  https://quilljs.com/docs/api
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop function onInput - Callback for when the field value changes.
 *  @prop string placeholder - Placeholder when empty.
 *  @prop string token - Set a custom token identifier for this field.
 *  @prop string value - Field value.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./wysiwygfield.scss";

import ReactQuill, { Quill } from 'react-quill';

import IconButton from "Components/UI/IconButton";
import SelectField from "Components/UI/Field/SelectField";
import TextareaField from "Components/UI/Field/TextareaField";

import Globals from "Class/Globals";
import { RandomToken } from "Functions";

const Block = Quill.import( "blots/block" );

// Create and register a Div class to allow div blots in Quill.
// https://github.com/quilljs/quill/issues/2040
class Div extends Block {}

Div.tagName = Div.blotName = "div";
Div.allowedChildren = Block.allowedChildren;
Div.allowedChildren.push( Block );

Quill.register( Div );

class WysiwygField extends React.Component {

    constructor( props ) {

        super( props );

        this.Editor = false;
        this.FocusValue = false;
        this.Token = RandomToken();

        this.state = {

            focus: false,
            format: {},
            length: 0,
            ready: false,
            source: false,
            value: ""

        };
        
    }

    /**
     * Set initial value and add listeners on mount.
     * 
     * @return void
     */

    componentDidMount() {

        const { value } = this.props;

        this.setState( { value } );

    }

    /**
     * Update value.
     * 
     * @return void
     */

    UNSAFE_componentWillReceiveProps( nextProps ) {

        const { value } = nextProps;

        if ( value !== this.props.value ) {
            
            this.setState( { value } );

        }

    }

    Format = ( e, action, option ) => {

        const { format } = this.state;
        const R = this.Editor ? this.Editor.getSelection() : false;

        if ( !R ) {

            return;

        }

        switch ( action ) {

            case "bold":

                if ( R.length || !format.bold ) this.Editor.formatText( R.index, R.length, "bold", !format.bold );
                else this.FormatBlot( R.index, "bold", false );
                break;

            case "header":

                this.Editor.formatLine( R.index, R.length, "header", parseInt( option, 10 ) );
                break;

            case "italic":

                if ( R.length || !format.italic ) this.Editor.formatText( R.index, R.length, "italic", !format.bold );
                else this.FormatBlot( R.index, "italic", false );
                break;

            case "underline":

                if ( R.length || !format.underline ) this.Editor.formatText( R.index, R.length, "underline", !format.underline );
                else this.FormatBlot( R.index, "underline", false );
                break;

            default:

        }

        this.OnChangeSelection( R );

    }

    FormatBlot = ( index, action, option ) => {

        const L = this.Editor ? this.Editor.getLeaf( index ) : false;

        if ( !L ) {

            return;

        }

        let Blot = L[0];

        while ( Blot && Blot.statics && Blot.statics.blotName !== action ) {

            Blot = Blot.parent;

        }

        if ( !Blot ) {

            return;

        }

        Blot.format( action, option );

    }

    LinkAdd = () => {

        const { format } = this.state;
        const R = this.Editor ? this.Editor.getSelection() : false;

        if ( !R ) {

            return;

        }

        Globals.DialogCreate( {

            type: "link",
            link: format.link || "",
            onLink: ( link ) => {

                if ( format.link && !R.length ) {

                    this.FormatBlot( R.index, "link", link );

                }
                
                else {
                    
                    this.Editor.formatText( R.index, R.length, "link", link );

                }

            }

        } );

    }

    LinkRemove = () => {

        const { format } = this.state;
        const R = this.Editor ? this.Editor.getSelection() : false;

        if ( !R || !format.link ) {

            return;

        }

        if ( !R.length ) {

            this.FormatBlot( R.index, "link", "" );

        }

        else {
            
            this.Editor.formatText( R.index, R.length, "link", "" );

        }

    }

    OnChange = ( value ) => {

        const { id, onChange } = this.props;

        this.setState( { value } );

        onChange( null, value, id );

    }

    OnChangeSelection = ( range ) => {

        const Format = ( this.Editor && range ) ? this.Editor.getFormat( range ) : false;

        this.setState( {

            format: Format || {},
            length: range ? range.length : 0

        } );

    }

    OnQuill = ( quill ) => {

        if ( !quill ) {

            return;

        }

        this.Editor = quill.getEditor();

        this.setState( {

            ready: true

        } );

    }

    OnSource = ( e, value ) => {

        if ( !this.Editor ) {

            return;

        }

        this.Editor.container.firstChild.innerHTML = value;

    }

    ToggleSource = () => {

        const { source } = this.state;
        const { html } = this.refs;
        const ShowSource = !source;

        this.setState( {

            source: ShowSource

        } );

        html.Adjust();

        if ( ShowSource && html ) {

            setTimeout( html.Adjust, 0 );

        }

    }

    render() {

        const {
            
            className,
            disabled,
            error,
            label,
            placeholder
            
        } = this.props;

        const {
            
            focus,
            format,
            length,
            ready,
            source,
            value
            
        } = this.state;


        const CA = [ "Field", "WysiwygField" ];

        if ( className ) CA.push( className );
        if ( disabled ) CA.push( "Disabled" );
        if ( error ) CA.push( "Error" );
        if ( focus ) CA.push( "Focus" );
        if ( source ) CA.push( "ShowSource" );
        if ( value ) CA.push( "HasValue" );

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                { label ?  <label htmlFor={ this.Token }>

                    { label }

                </label> : "" }

                <div className="WysiwygFieldToolbar">

                    <div className="WysiwygFieldToolbarGroup">

                        <SelectField
                    
                            disabled={ source }
                            onChange={ ( e, value ) => this.Format( e, "header", value ) }
                            options={{

                                1: "Rubrik 1",
                                2: "Rubrik 2",
                                3: "Rubrik 3",
                                0: "Normal"

                            }}
                            value={ format.header || 0 }
                        
                        />

                    </div>

                    <div className="WysiwygFieldToolbarGroup">

                        <IconButton
                        
                            active={ format.bold && !source }
                            disabled={ source || !ready }
                            id="bold"
                            feather="Bold"
                            onClick={ this.Format }
                        
                        />

                        <IconButton
                        
                            active={ format.italic && !source }
                            disabled={ source || !ready }
                            id="italic"
                            feather="Italic"
                            onClick={ this.Format }
                        
                        />

                        <IconButton
                        
                            active={ format.underline && !source }
                            disabled={ source || !ready }
                            id="underline"
                            feather="Underline"
                            onClick={ this.Format }
                        
                        />

                    </div>

                    <div className="WysiwygFieldToolbarGroup">

                        <IconButton
                        
                            active={ format.link && !source }
                            disabled={ source || ( !length && !format.link ) }
                            feather="Link"
                            onClick={ this.LinkAdd }
                        
                        />

                        <IconButton
                        
                            disabled={ source || !format.link }
                            feather="X"
                            onClick={ this.LinkRemove }
                        
                        />

                    </div>

                    <div className="WysiwygFieldToolbarGroup Right">

                        <IconButton
                        
                            active={ source }
                            feather="Code"
                            onClick={ this.ToggleSource }
                        
                        />

                    </div>

                </div>

                <ReactQuill
                
                    className="Input WysiwygFieldRich"
                    formats={[ "bold", "header", "italic", "link", "underline" ]}
                    modules={{ toolbar: false }}
                    onChange={ this.OnChange }
                    onChangeSelection={ this.OnChangeSelection }
                    placeholder={ placeholder }
                    ref={ this.OnQuill }
                    value={ value }
                
                />

                <TextareaField
                
                    className="WysiwygFieldSource"
                    onChange={ this.OnSource }
                    ref="html"
                    value={ value }
                    visibleRows={ 1 }
                
                />

            </div>

        );

    }

}

WysiwygField.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    label: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onInput: PropTypes.func,
    placeholder: PropTypes.string,
    token: PropTypes.string,
    value: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] )

};

WysiwygField.defaultProps = {

    className: "",
    disabled: false,
    error: false,
    id: "",
    label: "",
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    onInput: () => {},
    placeholder: "",
    token: "",
    value: ""

};

export default WysiwygField;
