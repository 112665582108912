
import React from "react";
import "./media.scss";

import Mailchimp from "Class/Mailchimp";

import Button from "Components/UI/Button";
import LoadImage from "Components/Layout/LoadImage";
import Spinner from "Components/Feedback/Spinner";

class ViewMedia extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            done: false,
            error: false,
            files: [],
            loading: false,
            offset: 0

        };

    }

    componentDidMount() {

        this.Load();
    
    }

    Item = ( media, index ) => {

        const { full_size_url, id, name } = media;

        return (

            <div
            
                className="MediaFileItem"
                key={ id }
                
            >

                <div className="MediaFileItemPreview">

                    <LoadImage
                    
                        src={ full_size_url }
                        
                    />

                </div>

                <div className="MediaFileItemContent">

                    <h3>{ name }</h3>

                </div>

            </div>

        );

    }

    Load = () => {

        const { offset } = this.state;

        this.setState( {

            error: false,
            loading: true

        } );

        Mailchimp.Media( response => {

            const { files } = this.state;
            const { error, files: fetched } = response || {};

            if ( error || typeof fetched !== "object" ) {

                this.setState( {

                    error: true,
                    loading: false

                } );

            }

            else {

                this.setState( {

                    done: !fetched.length,
                    loading: false,
                    offset: offset + fetched.length,
                    files: files.concat( fetched )

                } );

            }

        }, offset );

    }

    render() {

        const { dc } = Mailchimp.Meta;
        const { error, loading, files } = this.state;
        const CA = [ "Media" ];
        let Content = "";

        if ( error ) {

            CA.push( "Error" );
            Content = "Error."

        }

        else if ( files.length ) {

            const Items = [];

            files.forEach( ( file, index ) => {

                Items.push( this.Item( file, index ) );

            } );

            Content = (

                <div className="MediaFilesWrapper">

                    <div className="MediaFilesTop">

                        <Button
                        
                            label="Ladda upp"
                            to="/media/upload"
                        
                        />

                        <Button
                        
                            label="Visa i Mailchimp"
                            hollow={ true }
                            href={ `https://${dc}.admin.mailchimp.com/templates` }
                            feather="ExternalLink"
                        
                        />

                    </div>

                    <h2>Filer</h2>

                    <div className="MediaFilesItems">

                        { Items }

                    </div>

                    <Button
                    
                        label= "Hämta fler"
                        loading={ loading }
                        onClick={ this.Load }
                    
                    />

                </div>

            );

        }

        else if ( loading ) {

            Content = <Spinner className="PrimarySpinner" />

        }

        else {

            Content = "Inga filer hämtades."

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                { Content }
                
            </div>

        );

    }

}

export default ViewMedia;
