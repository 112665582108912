
/**!
 *  Handles global variables and methods.
 *
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import Settings from "Import/JSON/settings.json";
import { RandomToken } from "Functions";

class Globals {

    constructor() {

        this.Colors = [

            [ "#001489", "Blå", "blue" ],
            [ "#95d4ee", "Blå Ljus", "blue-light" ],
            [ "#ec9bba", "Rosa", "pink" ],
            [ "#afccae", "Grön", "green" ],
            [ "#4a4a4a", "Grå", "gray" ],
            [ "#f0f0f0", "Grå Ljus", "gray-light" ],
            [ "#ffffff", "Vit", "white" ]

        ];

        this.ColorsK = {};
        this.DialogList = {};
        this.Listeners = {};
        this.Settings = Settings || {};
        this.StorageIsEnabled = -1;
        this.StorageWarningSent = false;
        this.Vars = {};
        this.Width = 621;

        this.Colors.forEach( color => {

            this.ColorsK[ color[2] ] = color;

        } );

        // Trigger an event when fonts load to update section masks etc.
        if ( document.fonts ) {

            document.fonts.onloadingdone = this.OnLoadFont;

        }

    }

    /**
     * Save or Load a value from the clients browser.
     * TODO: Fallback when local/session storage is unavailable.
     *
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     * 
     * @return mixed - The setting value.
     */

    Client = ( key, value = null ) => {

        if ( this.StorageEnabled() ) {

            return this.Session( key, value ) || this.Storage( key, value );

        }

        else {

            if ( !this.StorageWarningSent ) {

                console.warn( "Storage is not available in this client. Falling back on global variables." );

                this.StorageWarningSent = true;

            }

            return this.Var( key, value );

        }

    }

    /**
     * Remove a value from the clients browser.
     * TODO: Fallback when local/session storage is unavailable.
     *
     * @param string key - The value key.
     * 
     * @return void
     */

    ClientRemove = ( key ) => {

        if ( this.StorageEnabled() ) {

            this.SessionRemove( key );
            this.StorageRemove( key );

        }

    }

    /**
     * Create and display an overlay dialog.
     *
     * @param object dialog - Dialog object.
     * 
     * @return string - The unique ID of the dialog.
     */

    DialogCreate = ( dialog ) => {

        const Id = RandomToken();

        this.Trigger( "dialog-create", Id, this.DialogList[ Id ] = dialog );

        return Id;

    }

    /**
     * Destroy a dialog.
     *
     * @param string id - The dialog id.
     * 
     * @return void
     */

    DialogDestroy = ( id, skipClose ) => {

        const Dialog = this.DialogList[ id ];

        if ( !Dialog ) {

            return;

        }

        if ( !skipClose && Dialog.props ) {

            const { onClose } = Dialog.props;

            if ( typeof onClose === "function" && onClose() === false ) {

                return;

            }

        }

        delete this.DialogList[ id ];

        this.Trigger( "dialog-destroy", id );

    }

    /**
     * Get the list of dialogs.
     * 
     * @return object - The dialogs list as { id: dialog }
     */

    Dialogs = () => {

        return this.DialogList;

    }

    /**
     * Get all event listeners for an event.
     *
     * @param string event - The event name
     * 
     * @return array - An array of listeners.
     */

    Get = ( event ) => {

        if ( this.Listeners[ event ] === undefined ) {

            this.Listeners[ event ] = [];

        }

        return this.Listeners[ event ];

    }

    /**
     * Increase and return the value of a global variable.
     *
     * @param string key - The value key.
     * 
     * @return mixed - The value.
     */

    Increase = ( key ) => {

        let Value = this.Vars[ key ];

        if ( Value === undefined ) {

            return this.Vars[ key ] = 0;

        }

        if ( typeof Value !== "number" ) {

            return Value;

        }

        return this.Vars[ key ] = Value + 1;

    }

    /**
     * Add an event listener
     *
     * @param string event - The event name
     * @param function callback - The callback function
     * 
     * @return void
     */

    Listen = ( event, callback ) => {

        if ( typeof callback !== "function" ) {

            return;

        }

        const Listeners = this.Get( event );
        const Index = Listeners.indexOf( callback );

        if ( Index < 0 ) {

            Listeners.push( callback );

        }

    }

    /**
     * Callback when a font loads.
     * 
     * @return void
     */

    OnLoadFont = (e) => {

        this.Trigger( "font" );

    }

    /**
     * Check if local/ession storage is available.
     *
     * @return boolean - Whether local/session storage is available.
     */

    StorageEnabled = () => {

        if ( this.StorageIsEnabled !== -1 ) {

            return this.StorageIsEnabled;

        }

        try {

            localStorage.setItem( "test", "test" );
            localStorage.removeItem( "test" );
            sessionStorage.setItem( "test", "test" );
            sessionStorage.removeItem( "test" );

            return this.StorageIsEnabled = true;

        }

        catch (e) {

            return this.StorageIsEnabled = false;

        }

    }

    /**
     * Remove an event listener
     *
     * @param string event - The event name
     * @param function callback - The callback function
     * 
     * @return void
     */

    Remove = ( event, callback ) => {

        const Listeners = this.Get( event );
        const Index = Listeners.indexOf( callback );

        if ( Index < 0 ) {

            return;

        }

        Listeners.splice( Index, 1 );

    }

    /**
     * Save or Load a value from the clients session storage.
     *
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     * 
     * @return mixed - The setting value.
     */
    
    Session = ( key, value = null ) => {

        if ( !this.StorageEnabled() ) {

            if ( !this.StorageWarningSent ) {

                console.warn( "Storage is not available in this client. Falling back on global variables." );

                this.StorageWarningSent = true;

            }

            return this.Var( key, value );

        }

        if ( value !== null ) {

            sessionStorage.setItem( key, value );

        }

        else {

            value = sessionStorage.getItem( key );

        }

        switch ( value ) {

            case "false": return false;
            case "true": return true;
            case null: return undefined;
            default: return value;

        }

    }

    /**
     * Remove a value from the clients session storage.
     *
     * @param string key - The value key.
     * 
     * @return void
     */
    
    SessionRemove = ( key ) => {

        if ( !this.StorageEnabled() ) {

            return undefined;

        }

        sessionStorage.removeItem( key );

    }

    /***
     * Get a setting value.
     *
     * @param string name - The setting name.
     * @param string defaultValue - The default value when not set.
     * 
     * @return mixed - The setting value.
     */
    
    Setting = ( name, defaultValue ) => {

        return typeof this.Settings[ name ] !== "undefined" ? this.Settings[ name ] : defaultValue;

    }
    
    /**
     * Save or Load a value from the clients local storage.
     *
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     * 
     * @return mixed - The setting value.
     */
    
    Storage = ( key, value = null ) => {

        if ( !this.StorageEnabled() ) {

            if ( !this.StorageWarningSent ) {

                console.warn( "Storage is not available in this client. Falling back on global variables." );

                this.StorageWarningSent = true;

            }

            return this.Var( key, value );

        }

        if ( value !== null ) {

            localStorage.setItem( key, value );

        }

        else {

            value = localStorage.getItem( key );

        }

        switch ( value ) {

            case "false": return false;
            case "true": return true;
            case null: return undefined;
            default: return value;

        }

    }
    
    /**
     * Load a value from the clients local storage and parse it as JSON.
     *
     * @param string key - The value key.
     * @param object defaultObject - Default object when missing/failed.
     * 
     * @return object - Parsed or default object.
     */

    StorageJson = ( key, defaultObject ) => {

        const Raw = this.Storage( key );

        if ( !Raw || typeof Raw !== "string" ) {

            return defaultObject || [];

        }

        let Decoded;

        try {

            Decoded = JSON.parse( Raw );

        }

        catch (e) {

            return defaultObject || [];

        }

        return typeof Decoded === "object" ? Decoded : defaultObject || [];

    }

    /**
     * Remove a value from the clients local storage.
     *
     * @param string key - The value key.
     * 
     * @return void
     */
    
    StorageRemove = ( key ) => {

        if ( !this.StorageEnabled() ) {

            return undefined;

        }

        localStorage.removeItem( key );

    }

    /**
     * Toggle a global variable between true/false.
     *
     * @param string key - The value key.
     * 
     * @return mixed - The value.
     */

    Toggle = ( key ) => {

        const Value = !this.Vars[ key ];

        return this.Var( key, Value );

    }

    /**
     * Trigger an event
     *
     * @param string event - The event name
     * @param mixed data1 - Optional. Data to send to the event listeners.
     * @param mixed data2 - Optional. Data to send to the event listeners.
     * @param mixed data3 - Optional. Data to send to the event listeners.
     * 
     * @return void
     */

    Trigger = ( event, data1, data2, data3 ) => {

        const Listeners = this.Get( event );

        Listeners.forEach( callback => {

            callback( data1, data2, data3 );

        } );

    }

    /**
     * Set or get the value of a global variable.
     *
     * @param string key - The value key.
     * @param mixed value - Optional. Set value.
     * 
     * @return mixed - The value.
     */

    Var = ( key, value ) => {

        if ( value === undefined || value === this.Vars[ key ] ) {

            return this.Vars[ key ];

        }

        this.Trigger( "var-" + key, value, this.Vars[ key ] );

        return this.Vars[ key ] = value;

    }

}

export default new Globals();