
import API from "Class/API";
import Globals from "Class/Globals";

class Mailchimp {

    constructor() {

        //this.Id         = "195651523920";
        //this.Redirect   = "http://127.0.0.1:3000/auth";
        this.Id         = "236154378600";
        this.Redirect   = "https://cancerfonden.rodolfo.nu/auth";

        this.AuthKey    = Globals.Session( "authKey" );
        this.AuthId     = Globals.Session( "authId" );

        this.Meta       = false;
        this.Mode       = -1;
        this.Registered = {};

        this.AuthBySession();

    }

    Authed = () => {

        return !!this.Meta;

    }

    AuthByCode = ( code, callback ) => {

        if ( typeof callback !== "function" ) {

            callback = () => {};

        }

        if ( !code ) {

            return callback( false );

        }

        API.Request( "mailchimp/auth", { code }, data => {

            callback( this.AuthSet( data ) );

        } );

    }

    AuthBySession = () => {

        if ( !this.AuthKey || !this.AuthId ) {

            this.Mode = 0;

            Globals.Trigger( "auth", false );

        }

        else {

            API.Request( "mailchimp/key", {

                key: this.AuthKey,
                id: this.AuthId

            }, this.AuthSet );

        }

    }

    AuthUrl = () => {

        return `https://login.mailchimp.com/oauth2/authorize?response_type=code&client_id=${this.Id}&redirect_uri=${this.Redirect}`;

    }

    AuthSet = ( data ) => {

        const { credentials, meta } = data;

        if ( !credentials || !meta ) {

            this.Mode = 0;

            Globals.Trigger( "auth", false );

            return false;

        }

        const { key, id } = credentials;

        this.AuthKey    = Globals.Session( "authKey", key );
        this.AuthId     = Globals.Session( "authId", id );
        this.Meta       = meta;
        this.Mode       = 1;

        Globals.Trigger( "auth", meta );

        return true;

    }

    Logout = () => {

        if ( !this.Authed() ) {

            return;

        }

        API.Request( "mailchimp/logout" );

        this.AuthKey    = Globals.SessionRemove( "authKey" );
        this.AuthId     = Globals.SessionRemove( "authId" );
        this.Meta       = false;
        this.Mode       = 0;

        Globals.Trigger( "auth", false );

    }

    Media = ( callback, offset = 0, limit = 12 ) => {

        API.Request( "mailchimp/media", {

            offset,
            limit

        }, callback );

    }

    MediaMeta = ( images, callback ) => {

        if ( typeof callback !== "function" ) {

            callback = () => {};

        }

        if ( typeof images !== "object" ) {

            callback( [] );

        }

        API.Request( "mailchimp/media-meta", { images }, callback );

    }

    Ready = () => {

        return this.Mode !== -1;

    }

    RegisterTemplate = ( template ) => {

        const { id: localId } = template.local || {};

        if ( !localId ) {

            return;

        }

        return this.Registered[ localId ] = template.local;

    }

    Template = ( id, callback ) => {

        if ( typeof callback !== "function" ) {

            callback = () => {};

        }

        if ( this.Registered[ id ] !== undefined ) {

            callback( this.Registered[ id ] );

        }

        API.Request( "templates/get", { template: id }, response => {

            const { error, template } = response;

            if ( error || !template ) {

                callback( false );

            }

            else {
                
                callback( this.Registered[ id ] = template );

            }

        } );

    }

    Templates = ( callback, offset = 0, limit = 10 ) => {

        API.Request( "mailchimp/templates", {

            offset,
            limit

        }, callback );

    }

}

export default new Mailchimp();