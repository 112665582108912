
/**!
 *  Image gallery view
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./imagegallery.scss";

import Globals from "Class/Globals";
import Mailchimp from "Class/Mailchimp";
import { ArrayClone } from "Functions";

import Button from "Components/UI/Button";
import Error from "Components/Feedback/Error";
import FileUpload from "Components/UI/FileUpload";
import IconItem from "Components/UI/IconItem";
import ImageItem from "Components/UI/ImageItem";
import ScrollView from "Components/UI/ScrollView";
import Spinner from "Components/Feedback/Spinner";
import TabMenu from "Components/UI/TabMenu";

class ImageGallery extends React.Component {

    constructor( props ) {

        super( props );
        
        this.Limit = 12;
        this.Mounted = false;
        this.ScrollTimer = false;
        this.Tabs = [ "Galleri", "Ladda upp" ];
        this.TabKeys = Object.keys( this.Tabs );

        this.state = {

            ctrl: false,
            done: false,
            error: false,
            files: [],
            loading: false,
            offset: 0,
            ready: false,
            shift: false,
            selected: [],
            tab: 0

        }

    }

    /**
     *  Load gallery and add listeners on mount.
     *  
     *  @return void.
     */

    componentDidMount() {

        this.Mounted = true;

        this.Load();

        window.addEventListener( "keydown", this.OnKeyDown );
        window.addEventListener( "keyup", this.OnKeyUp );

        Globals.Listen( "upload-done", this.OnUploadDone );
        Globals.Listen( "upload-error", this.OnUploadError );
        Globals.Listen( "upload-progress", this.OnUploadProgress );
        Globals.Listen( "upload-start", this.OnUploadStart );

    }

    /**
     *  Remove listeners on unmount.
     *  
     *  @return void.
     */

    componentWillUnmount() {

        this.Mounted = false;

        window.removeEventListener( "keydown", this.OnKeyDown );
        window.removeEventListener( "keyup", this.OnKeyUp );

        Globals.Remove( "upload-done", this.OnUploadDone );
        Globals.Remove( "upload-error", this.OnUploadError );
        Globals.Remove( "upload-progress", this.OnUploadProgress );
        Globals.Remove( "upload-start", this.OnUploadStart );

    }

    /**
     *  Load gallery images.
     *  
     *  @return void.
     */

    Load = () => {

        const { offset } = this.state;

        this.setState( {
            
            error: false,
            loading: true

        } );

        Mailchimp.Media( response => {

            const { files } = this.state;
            const { error, files: fetched } = response || {};

            if ( error || typeof fetched !== "object" ) {

                this.setState( {

                    error: true,
                    loading: false

                } );

            }

            else {

                this.setState( {

                    done: !fetched.length,
                    loading: false,
                    offset: offset + fetched.length,
                    files: files.concat( fetched )

                } );

            }

        }, offset, this.Limit );

    }

    /**
     *  Cancel all active upload processes.
     *  
     *  @return void.
     */

    OnAbort = () => {

        const { upload } = this.refs;

        if ( !upload ) {

            return;

        }

        // Delegate to file upload component
        upload.OnAbort();

    }

    /**
     *  Callback when the close button is clicked.
     *  
     *  @return void.
     */

    OnClose = () => {

        const { id, onClose } = this.props;

        this.OnAbort();

        onClose( id );

    }

    /**
     *  Callback when a gallery item is clicked.
     * 
     *  @param object e - The event object.
     *  @param object image - The corresponding image object.
     *  @param string token - The item/image token.
     *  
     *  @return void.
     */

    OnImage = ( e, image, id ) => {

        if ( e.button !== 0 ) {

            return;

        }

        e.stopPropagation();
        e.preventDefault();

        const { multiple } = this.props;
        const { ctrl, images, selected, shift } = this.state;
        const Index = selected.indexOf( id );
        const IsSelected = Index >= 0;
        let Selected = [];

        if ( ( !multiple || !selected.length || ( !ctrl && !shift ) ) && !IsSelected ) {

            Selected.push( id );

        }

        else if ( multiple && ctrl ) {

            Selected = ArrayClone( selected );

            if ( IsSelected ) {

                Selected.splice( Index, 1 );

            }

            else {

                Selected.push( id );

            }

        }

        else if ( multiple && shift ) {

            const First = selected[0];
            const Tokens = Object.keys( images );
            const From = Tokens.indexOf( First );
            const To = Tokens.indexOf( id );

            if ( From >= 0 && To >= 0 ) {

                const Step = To > From ? 1 : -1;

                for ( let i = From; i !== To + Step; i += Step ) {

                    Selected.push( Tokens[i] );

                }

            }

        }

        this.setState( {

            ready: this.Ready( Selected ),
            selected: Selected

        } );

    }

    /**
     *  Callback when any button is pressed when the gallery is mounted.
     *  Catches shift and ctrl in order to change selection mode.
     * 
     *  @param object e - The event object.
     *  
     *  @return void.
     */

    OnKeyDown = (e) => {

        const { shift } = this.state;

        switch( e.key ) {

            case "Shift":

                this.setState( {

                    ctrl: false,
                    shift: true

                } );

                break;

            case "Control":
            case "Meta":

                e.preventDefault();

                if ( shift ) {

                    break;

                }

                this.setState( {

                    ctrl: true

                } );

                break;

            default:

        }

    }

    /**
     *  Callback when any button is released when the gallery is mounted.
     * 
     *  @param object e - The event object.
     *  
     *  @return void.
     */

    OnKeyUp = (e) => {

        switch( e.key ) {

            case "Shift":

                this.setState( {

                    shift: false

                } );

                break;

            case "Control":
            case "Meta":

                e.preventDefault();

                this.setState( {

                    ctrl: false

                } );

                break;

            default:

        }

    }

    /**
     *  Callback when the select button is clicked. Gathers all image objects
     *  into an array and puts it into a callback function.
     *  
     *  @return void.
     */

    OnSelect = () => {

        const { id, onSelect } = this.props;
        const { files, selected } = this.state;
        const Images = [];

        files.forEach( image => {

            if ( selected.indexOf( image.id ) < 0 ) {

                return;

            }

            Images.push( image );

        } );

        Images.sort( ( a, b ) => {

            const I1 = selected.indexOf( a.id );
            const I2 = selected.indexOf( b.id );

            if ( I1 < I2 ) return -1;
            if ( I1 > I2 ) return +1;

            return 0;

        } );

        onSelect( selected, Images, id );

    }

    /**
     *  Callback when an image finished uploading.
     * 
     *  @param string token - The image token.
     *  @param object data - An object containing the file object.
     *  
     *  @return void.
     */

    OnUploadDone = ( token, data ) => {

        const { images, selected } = this.state;
        const { file } = data;

        images[ token ] = file;

        this.setState( {
            
            images,
            ready: this.Ready( selected, images )
            
        } );

    }

    /**
     *  Callback when an error occurrs when uploading an image.
     * 
     *  @param string token - The image token.
     *  
     *  @return void.
     */

    OnUploadError = ( token ) => {

        const { images } = this.state;
        const Image = images[ token ];
        
        if ( !Image ) {

            return;

        }

        Image.error = true;

        this.setState( { images } );

    }

    /**
     *  Callback when an image file upload progress refreshed.
     * 
     *  @param string token - The image token.
     *  @param object data - An object containing progress data.
     *  
     *  @return void.
     */

    OnUploadProgress = ( token, data ) => {

        const { images } = this.state;
        const { loaded, total } = data;
        const Image = images[ token ];
        const Progress = loaded / total;

        if ( !Image ) {

            return;

        }

        Image.progress = Progress;

        this.setState( { images } );

    }

    /**
     *  Callback when an image upload starts.
     * 
     *  @param string token - The image token.
     *  @param object data - Initial image info.
     *  
     *  @return void.
     */

    OnUploadStart = ( token, data ) => {

        const { images, selected } = this.state;
        const { filename, index, modified, preview } = data;
        const Tokens = Object.keys( images );
        const Images = {};
        const Selected = index ? selected : [];

        if ( Tokens.indexOf( token ) >= 0 ) {

            return;

        }

        Images[ token ] = {

            filename,
            modified,
            token,
            preview,
            progress: 0

        };

        Selected.push( token );

        Tokens.forEach( t => {

            Images[t] = images[t];

        } );

        const State = {
            
            images: Images,
            selected: Selected
            
        };

        // Switch to the gallery tab when the (first) upload starts.
        if ( !index ) {

            State.tab = 0;

        }

        this.setState( State );

    }

    /**
     *  Check whether the current selection is ready, ie. that the selection
     *  is not empty and that all images are loaded.
     * 
     *  @param array selection - Optional input. Defaults to state.selected.
     *  @param array gallery - Optional gallery. Defaults to state.images.
     *  
     *  @return boolean - Whether the selection is ready.
     */

    Ready = ( selection, gallery ) => {

        const { selected } = this.state;
        const Selected = selection || selected;
        
        if ( !Selected.length ) {

            return false;

        }

        let Ready = true;

        return Ready;

    }

    /**
     *  Callback when a tab menu item is clicked.
     * 
     *  @param object e - The event object.
     *  @param integer tab - The index of the clicked tab item.
     *  
     *  @return void.
     */

    SetTab = ( e, tab ) => {

        const Tab = this.TabKeys.indexOf( tab );
        
        this.setState( { tab: Tab } );

    }

    render() {

        const { className, multiple } = this.props;
        const { done, error, files, loading, ready, selected, tab } = this.state;
        const CA = [ "ImageGallery" ];
        const Content = [];
        const Empty = !files.length;

        if ( className ) {

            CA.push( className );

        }

        if ( loading && Empty ) {

            Content.push( <Spinner
            
                className="ImageGallerySpinner"
                key="spinner"
                overlay={ true }
            
            /> );

        }

        else if ( error && Empty ) {

            Content.push( <Error

                className="ImageGalleryError"
                button="Försök igen"
                key="error"
                label="Kunde inte ladda galleriet"
                onClick={ () => this.Load() }
            
            /> );

        }

        else if ( Empty ) {

            Content.push( <div
            
                className="ImageGalleryEmpty"
                key="empty"
            
            >No images in gallery</div> );

        }

        else {

            const Gallery = [];

            files.forEach( file => {

                const { id } = file;

                Gallery.push( <ImageItem
                
                    active={ selected.indexOf( id ) >= 0 }
                    className="ImageGalleryItem"
                    id={ id }
                    image={ file }
                    key={ id }
                    onClick={ this.OnImage }
                
                /> );

            } );

            Gallery.push( <IconItem
            
                className="ImageGalleryMore"
                disabled={ done }
                label="Ladda mer"
                loading={ loading }
                onClick={ () => this.Load() }
                key="load"
                feather="PlusCircle"
            
            /> );

            Content.push( <ScrollView
            
                className="ImageGalleryItems"
                key="items"
            
            >{ Gallery }</ScrollView> );

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                <TabMenu
                
                    active={ this.TabKeys[ tab ] }
                    className="ImageGalleryTabMenu"
                    disabled={ loading }
                    items={ this.Tabs }
                    onClick={ this.SetTab }
                
                />

                <div className="ImageGalleryTabs">

                    <div className={ tab === 0 ? "ImageGalleryTab Active" : "ImageGalleryTab" }>

                        { Content }

                    </div>

                    <div className={ tab === 1 ? "ImageGalleryTab Active" : "ImageGalleryTab" }>

                        <FileUpload
                        
                            accept={[ "image/gif", "image/jpeg", "image/png" ]}
                            multiple={ multiple }
                            ref="upload"
                        
                        />

                    </div>

                </div>

                <div className="ImageGalleryTray">

                    <Button
                    
                        disabled={ !ready || tab !== 0 }
                        label="Välj"
                        onClick={ this.OnSelect }
                        small={ true }
                    
                    />

                    <Button
                    
                        hollow={ true }
                        label="Stäng"
                        onClick={ this.OnClose }
                        small={ true }
                    
                    />

                </div>

            </div>

        );

    }

}

ImageGallery.propTypes = {

    className: PropTypes.string,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    multiple: PropTypes.bool,
    onClose: PropTypes.func,
    onSelect: PropTypes.func

};

ImageGallery.defaultProps = {

    className: "",
    id: "",
    multiple: true,
    onClose: () => {},
    onSelect: () => {},
    selected: []

};

export default ImageGallery;