
/**!
 *  Tab menu.
 *
 *  @prop integer active - Active key.
 *  @prop string className - Append a class name.
 *  @prop boolean|array disabled - Whether to disable the entire menu or specific keys.
 *  @prop string id - Menu id.
 *  @prop array items - Tab items when no children have been supplied.
 *  @prop function onClick - Callback when a item is clicked.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./tabmenu.scss";

class TabMenu extends React.Component {

    /**
     * Check if an item is disabled.
     * 
     * @param string|integer key - The item key.
     * 
     * @return boolean - Whether the item is disabled.
     */

    Disabled = ( key ) => {

        const { disabled } = this.props;

        return disabled === true || ( typeof disabled === "object" && disabled[ key ] );

    }

    /**
     * Output a menu item.
     * 
     * @param string|JSX item - The item content.
     * @param string|integer key - The item key.
     * 
     * @return JSX - The menu item.
     */

    Item = ( item, key ) => {

        const { active } = this.props;
        const CA = [ "TabMenuItem" ];

        if ( active === key ) {

            CA.push( "Active" );

        }

        if ( this.Disabled( key ) ) {

            CA.push( "Disabled" );

        }

        const CS = CA.join( " " );

        return (

            <div
            
                className={ CS }
                key={ key }
                onClick={ e => this.OnClick( e, key ) }
            
            >{ item }</div>

        );

    };

    /**
     * Callback when an item is clicked
     * 
     * @param object e - The click event.
     * @param string|integer key - The item key.
     * 
     * @return void
     */

    OnClick = ( e, key ) => {

        if ( this.Disabled( key ) ) {

            return;

        }

        const { id, onClick } = this.props;

        onClick( e, key, id );

    }

    render() {

        const { children, className, items } = this.props;
        const CA = [ "TabMenu" ];

        if ( className ) {

            CA.push( className );

        }

        const CS = CA.join( " " );
        const Items = children || items;
        const Tabs = [];

        for ( let key in Items ) {

            Tabs.push( this.Item( Items[ key ], key ) );

        }

        return (

            <div className={ CS }>

                { Tabs }

            </div>

        );

    }

}

TabMenu.propTypes = {

    active: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    className: PropTypes.string,
    disabled: PropTypes.oneOfType( [ PropTypes.bool, PropTypes.array ] ),
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    items: PropTypes.oneOfType( [ PropTypes.array, PropTypes.object ] ),
    onClick: PropTypes.func

};

TabMenu.defaultProps = {

    active: -1,
    className: "",
    disabled: false,
    id: "",
    items: [],
    onClick: () => {}

};

export default TabMenu;