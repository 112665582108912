
import React from "react";
import "./core.scss";

import Globals from "Class/Globals";
import Mailchimp from "Class/Mailchimp";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Center from "Components/Layout/Center";
import Header from "Components/View/Header";
import Spinner from "Components/Feedback/Spinner";
import ViewAuth from "Components/View/Auth";
import ViewDialog from "Components/View/Dialog";
import ViewMedia from "Components/View/Media";
import ViewTemplateEdit from "Components/View/TemplateEdit";
import ViewTemplateNew from "Components/View/TemplateNew";
import ViewTemplates from "Components/View/Templates";

class Core extends React.Component {

    constructor( props ) {

        super( props );

        this.Router = false;
        this.Touch = "ontouchstart" in window;

        this.state = {

            authed: false,
            loading: false

        };

    }

    componentDidMount() {

        Globals.Listen( "auth", this.OnAuth );

        this.setState( {

            authed: Mailchimp.Authed(),
            loading: !Mailchimp.Ready()

        } );

    }

    componentWillUnmount() {

        Globals.Remove( "auth", this.OnAuth );

    }

    OnAuth = () => {

        this.setState( {

            authed: Mailchimp.Authed(),
            loading: false

        } );

    }

    /**
     * Set title on navigation.
     * Scroll up on forward navigation.
     * 
     * @return void
     */

    OnNavigation = ( location ) => {

        const Path = location.pathname;

        // Set global path var.
        Globals.Var( "path", Path.length > 1 ? Path.substr(1) : Path );

        if ( location.action === "POP" ) {

            return;

        }

        window.scrollTo( 0, 0 );

    }

    /**
     * Add navigation listener on Router mount.
     * 
     * @return void
     */

    OnRouter = ( router ) => {

        if ( !router ) {

            return;

        }

        const Path = router.history.location.pathname;

        // Set global path var.
        Globals.Var( "path", Path.length > 1 ? Path.substr(1) : Path );

        this.Router = router;

        router.history.listen( this.OnNavigation );

    }

    /**
     * Render route.
     * 
     * @return void
     */

    Route = ( routeProps ) => {

        const { authed } = this.state;
        const { location } = routeProps;
        const { pathname } = location || {};

        if ( !authed ) {

            return <ViewAuth />;

        }

        const RawPath = ( pathname || "" ).replace( /^\/|\/$/g, "" );
        const Path = RawPath.replace( /\/\d*$/g, "" );
        const Id = parseInt( RawPath.replace( /.*\/|[^0-9]/g, "" ), 10 );

        switch ( Path ) {

            case "media":

                return <ViewMedia />;

            case "templates/copy":

                return <ViewTemplateEdit copy={ true } key="copy" id={ Id } />;

            case "templates/edit":

                return <ViewTemplateEdit key="edit" id={ Id } />;

            case "templates/new":

                return <ViewTemplateNew />;

            default:

                return <ViewTemplates />;

        }

    }

    render() {

        const { loading } = this.state;
        const CA = [ "Core" ];

        if ( this.Touch ) {

            CA.push( "Touch" );

        }

        else {

            CA.push( "NoTouch" );

        }

        if ( loading ) {

            CA.push( "Loading" );

        }
        
        const CS = CA.join( " " );

        return loading ? (

            <Center className={ CS }>

                <Spinner className="PrimarySpinner" />

            </Center>

        ) : (

            <Router
        
                ref={ this.OnRouter }
                
            >

                <div className={ CS }>

                    <ViewDialog />

                    <Header />

                    <Switch>

                        <Route path="/auth" component={ ViewAuth } />
                        <Route render={ this.Route } />

                    </Switch>

                </div>

            </Router>

        );

    }

}

export default Core;