
/**!
 *  Loading spinner.
 *
 *  @prop string className - Append a class name.
 *  @prop integer size - Size in pixels.
 *  @prop boolean overlay - Whether the spinner should be centered on top of its container.
 *
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./spinner.scss";

import Icon from "Components/Layout/Icon";

class Spinner extends React.Component {

    render() {

        const CA = [ "SpinnerContainer" ];
        const { className, overlay, size } = this.props;

        if ( className ) CA.push( className );
        if ( overlay )  CA.push( "Absolute" );
        else CA.push( "Relative" );

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                <div className="Spinner" style={{ width: size, height: size }}>
                
                    <Icon
                    
                        className="SpinnerIcon"
                        feather="Loader"
                        size={ size }
                    
                    />
                
                </div>

            </div>

        );

    }

}

Spinner.propTypes = {

    className: PropTypes.string,
    size: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    overlay: PropTypes.bool

};

Spinner.defaultProps = {

    className: "",
    size: 60,
    overlay: false

}

export default Spinner;