
/*!
 *  Select form field.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field is disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop boolean flip - Whether to make the options appear above the field instead of below.
 *  @prop string id - Field ID.
 *  @prop string label - Field label. Overrides children.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback function.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop array|object options - Field options.
 *  @prop string placeholder - Placeholder/unselected value.
 *  @prop string value - Field value.
 *  @prop function selectedLabel - Optional callback to set the selected label.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./selectfield.scss";

import Icon from "Components/Layout/Icon";
import Sticky from "Components/Layout/Sticky";
import { ObjectAssign } from "Functions";

class SelectField extends React.Component {

    constructor( props ) {

        super( props );

        this.Options = {};

        this.state = {

            expand: false,
            focus: false,
            value: false,
            width: 200,

        };

    }

    /**
     * Set initial value and options and add listeners.
     * 
     * @return void
     */

    componentDidMount() {

        const { options, value } = this.props;

        this.SetValue( value, options, true );
        this.SetSize();

        window.addEventListener( "resize", this.SetSize );

    }

    /**
     * Update value and options.
     * 
     * @return void
     */

    UNSAFE_componentWillReceiveProps( nextProps ) {

        const { disabled, options, value } = nextProps;

        // Collapse on disable.
        if ( disabled ) {

            this.setState( { expand: false } );

        }

        if ( value !== this.props.value || options !== this.props.options ) {

            this.SetValue( value, options, true );

        }

    }

    /**
     * Remove listeners.
     * 
     * @return void
     */

    componentWillUnmount() {

        window.removeEventListener( "resize", this.SetSize );

    }

    /**
     * Output a option.
     * 
     * @param string label - The option label.
     * @param string key - The option key.
     * @param boolean selected - Whether this option is selected.
     * 
     * @return JSX - The option.
     */

    Item = ( label, key, selected ) => {

        const CA =[ "Option" ];

        if ( selected ) CA.push( "Selected" );

        const CS = CA.join( " " );

        return (
        
            <div
            
                key={ key }
                className={ CS }
                onClick={ () => this.SetValue( key ) }
                title={ typeof label === "string" ? label : "" }
                
            >
            
                { label }
                
            </div>

        );

    }

    /**
     * Callback for when the field loses focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnBlur = (e) => {

        const { id, onBlur } = this.props;
        const { value } = this.state;

        onBlur( e, value, id );

        this.setState( { focus: false } );

        window.removeEventListener( "keydown", this.OnKeyDown );
        window.removeEventListener( "keyup", this.OnKeyUp );

    }

    /**
     * Callback for when the field gains focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnFocus = (e) => {

        const { id, onFocus } = this.props;
        const { value } = this.state;

        onFocus( e, value, id );

        this.setState( { focus: true } );

        // Listen for key presses while focused.
        window.addEventListener( "keydown", this.OnKeyDown );
        window.addEventListener( "keyup", this.OnKeyUp );

    }

    /**
     * Callback for when a key is pressed while the field has focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnKeyDown = (e) => {

        const { disabled, id, onChange } = this.props;
        const { expand } = this.state;

        if ( disabled ) {

            return;

        }

        const { value } = this.state;
        const Options = this.Options;
        const Keys = Object.keys( Options );
        const Limit = Keys.length - 1;
        const Index = Keys.indexOf( value );

        let Value;

        switch ( e.which ) {

            // Enter = Toggle expand/collpase.
            case 13:

                this.setState( { expand: !expand } );
                break;

            // Up/Left = Previous option.
            case 37:
            case 38:

                Value = Keys[ Index <= 0 ? 0 : Index - 1 ];

                onChange( e, Value, id );

                this.setState( { value: Value } );
                break;

            // Down/Right = Next option.
            case 39:
            case 40:

                Value = Keys[ Index >= Limit ? Limit : Index + 1 ];

                onChange( e, Value, id );

                this.setState( { value: Value } );
                break;

            default:
                
                return;
                
        }

        e.stopPropagation();
        e.preventDefault();

    }

    /**
     * Callback for when a key is released while the field has focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnKeyUp = (e) => {

        e.stopPropagation();
        e.preventDefault();

    }

    /**
     * Toggle between expanded/collapsed.
     * 
     * @return void
     */

    OnToggle = () => {

        const { disabled } = this.props;
        const { expand } = this.state;
        const { input } = this.refs;

        if ( disabled || !input ) {

            return;

        }

        if ( expand ) {

            input.blur();

        }

        else {

            this.SetSize();

            input.focus();

        }

        this.setState( { expand: !expand } );

    }

    /**
     * Get the selected options label.
     * 
     * @return string - The selected options label.
     */

    Selected = () => {

        const { placeholder, selectedLabel } = this.props;
        const { value } = this.state;

        const SelectedLabel = selectedLabel( value );

        if ( SelectedLabel ) {

            return SelectedLabel;

        }

        const Options = this.Options;
        const Keys = Object.keys( Options );

        if ( !Keys.length ) {

            return "";

        }

        return Options[ value ] || placeholder || Options[ Keys[0] ];

    }

    /**
     * Adjust the width of the dropdown menu when the client resizes.
     * 
     * @return void
     */

    SetSize = () => {

        const { input } = this.refs;

        if ( !input ) {

            return;

        }

        this.setState( { width: input.offsetWidth } );

    }

    /**
     * Update the selected value and options.
     * 
     * @param string key - The selected option key.
     * @param array|object options - Select options.
     * @param boolean noCallback - Whether to skip onChange().
     * 
     * @return string - The selected options label.
     */

    SetValue = ( key, options, noCallback = false ) => {

        const { id, onChange, placeholder } = this.props;
        
        this.Options = ObjectAssign( {}, options || this.Options );

        const Keys = Object.keys( this.Options );

        if ( !Keys.length ) {

            return false;

        }

        if ( typeof this.Options[ key ] === "undefined" ) {

            key = placeholder ? -1 : Keys[0];

        }

        if ( !noCallback ) {

            onChange( null, key, id );

        }

        this.setState( {
            
            expand: false,
            value: key
            
        } );

    }

    /**
     * Get the selected option label.
     * 
     * @return string - The selected options label.
     */

    Value = () => {

        return this.Selected() || "";

    }

    render() {

        const { className, disabled, error, flip, label, placeholder } = this.props;
        const { expand, focus, value, width } = this.state;
        const CA = [ "Field", "SelectField" ];
        const NumOptions = Array.isArray( this.Options ) ? this.Options.length : Object.keys( this.Options ).length;

        if ( className ) CA.push( className );
        if ( disabled || !NumOptions ) CA.push( "Disabled" );
        if ( error ) CA.push( "Error" );
        if ( expand ) CA.push( "Expand" );
        if ( flip ) CA.push( "Flip" );
        if ( focus ) CA.push( "Focus" );

        const CS = CA.join( " " );
        const Selected = this.Selected();
        
        let Menu, Index = 0;

        if ( expand ) {

            const Items = [];

            if ( placeholder ) {

                Items.push( this.Item( placeholder, -1, value === -1 ) );

            }

            for ( let key in this.Options ) {

                let Item = this.Options[ key ];
                let IsSelected = ( !Index && !key  ) || key === value;

                Items.push( this.Item( Item, key, IsSelected ) );

                Index++;

            }

            Menu = (

                <Sticky
                
                    align="right"
                    className="SelectFieldList"
                    flip={ flip }
                    onClose={ this.OnToggle }
                    width={ width }
                    
                >
                
                    { Items }
                
                </Sticky>

            );

        }

        return (

            <div className={ CS }>

                {
                
                    label ? (
                    
                        <label onClick={ this.OnToggle }>
                    
                            { label }
                        
                        </label>
                    
                    ) : ""

                }

                <div

                    className="Input"
                    onBlur={ this.OnBlur }
                    onClick={ this.OnToggle }
                    onFocus={ this.OnFocus }
                    ref="input"
                    tabIndex="0"
                    title={ Selected }

                >

                    <span>{ Selected }</span>

                    <Icon feather={ flip ? "ChevronUp" : "ChevronDown" } />

                </div>

                { Menu }

            </div>            

        );

    }

}

SelectField.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    flip: PropTypes.bool,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    label: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    options: PropTypes.oneOfType( [ PropTypes.array, PropTypes.object ] ),
    placeholder: PropTypes.string,
    selectedLabel: PropTypes.func

};

SelectField.defaultProps = {

    className: "",
    disabled: false,
    error: false,
    flip: false,
    id: "",
    label: "",
    onBlur: () => {},
    onChange: () => {},
    onFocus: () => {},
    options: [],
    placeholder: "",
    selectedLabel: () => {},
    value: -1

};

export default SelectField;