
/*!
 *  Button.
 *
 *  @prop boolean big - Whether the button should be big.
 *  @prop string className - Append a class name.
 *  @prop string color - Button color.
 *  @prop boolean done - Whether the button should have the check icon. Same behaviour as 'disabled'.
 *  @prop boolean disabled - Whether the button should be disabled.
 *  @prop boolean hollow - Whether the button should be hollow.
 *  @prop string href - Optional link href.
 *  @prop string id - Button ID.
 *  @prop string label - Button label.
 *  @prop boolean loading - Whether the label should be replaced with a spinner.
 *  @prop function onBlur - Callback for when the button loses focus.
 *  @prop function onClick - Callback for when the button is clicked.
 *  @prop function onFocus - Callback for when the button gains focus.
 *  @prop string target - Optional. Link target window when a href has been specified.
 *  @prop string title - The button title tag.
 *  @prop string to - URI path when used for internal navigation.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./button.scss";

import Icon from "Components/Layout/Icon";
import Link from "Components/UI/Link";
import Spinner from "Components/Feedback/Spinner";

class Button extends React.Component {

    constructor( props ) {

        super( props );

        this.Mounted = false;
        this.Width = 0;

        this.state = {

            down: false
            
        };

    }

    /**
     * Register mount.
     * 
     * @return void
     */

    componentDidMount() {

        this.Mounted = true;

    }

    /**
     * Register unmount.
     * 
     * @return void
     */

    componentWillUnmount() {

        this.Mounted = false;

    }

    /**
     * Callback for when the field loses focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnBlur = (e) => {

        if ( !this.Mounted ) {

            return;

        }

        const { id, onBlur } = this.props;

        onBlur( e, id );

        this.setState( { focus: false } );

        window.removeEventListener( "keydown", this.OnKeyDown );
        window.removeEventListener( "keyup", this.OnKeyUp );

    }

    /**
     * Callback for when the button is clicked.
     * 
     * @param object e - The click event.
     * 
     * @return void 
     */

    OnClick = (e) => {

        const { disabled, done, id, loading, onClick } = this.props;

        if ( disabled || done || loading ) {

            return;

        }

        onClick( e, id );

    }

    /**
     * Callback for when the field gains focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnFocus = (e) => {

        if ( !this.Mounted ) {

            return;

        }

        const { id, onFocus } = this.props;

        onFocus( e, id );

        this.setState( { focus: true } );

        // Listen for enter press while focused.
        window.addEventListener( "keydown", this.OnKeyDown );
        window.addEventListener( "keyup", this.OnKeyUp );

    }

    /**
     * Callback for when a key is pressed while the button has focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnKeyDown = (e) => {

        if ( !this.Mounted ) {

            return;

        }

        e.stopPropagation();
        e.preventDefault();

        const { disabled } = this.props;

        if ( disabled || e.which !== 13 ) {

            return;

        }

        this.setState( { down: true } );

    }

    /**
     * Callback for when a key is released while the button has focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnKeyUp = (e) => {

        if ( !this.Mounted ) {

            return;

        }

        e.stopPropagation();
        e.preventDefault();

        const { disabled, id, onClick } = this.props;

        this.setState( { down: false } );

        if ( disabled || e.which !== 13 ) {

            return;

        }

        onClick( e, id );

    }

    render() {

        const {
            
            big,
            children,
            className,
            color,
            disabled,
            done,
            feather,
            hollow,
            href,
            label,
            loading,
            small,
            target,
            title,
            to
            
        } = this.props;
        
        const { down } = this.state;
        const CA = [ "ButtonContainer" ];

        if ( big ) CA.push( "Big" );
        if ( className )  CA.push( className );
        if ( disabled ) CA.push( "Disabled" );
        if ( done ) CA.push( "Done" );
        if ( down ) CA.push( "Down" );
        if ( hollow ) CA.push( "Hollow" );
        else {

            switch ( color ) {

                default:

                    CA.push( "Blue" );

            }

        }

        if ( loading ) CA.push( "Loading" );
        if ( small ) CA.push( "Small" );

        const CS = CA.join( " " );
        const Ic = done ? <Icon
        
            className="ButtonIcon"
            size={ 24 }
            feather="Check"
        
        /> : "";

        const Sp = loading ? <Spinner
        
            className="ButtonSpinner"
            size={ 32 }
            
        /> : "";

        const Fe = feather ? <Icon
        
            className="ButtonFeather"
            size={ 24 }
            feather={ feather }

        /> : "";

        if ( href || to ) {

            return (

                <span className={ CS }>
            
                    <Link
                    
                        className="Button"
                        href={ href }
                        onClick={ this.OnClick }
                        target={ target }
                        title={ title || label }
                        to={ to }
                        
                    >

                        { Ic }

                        <div className="ButtonContent" ref="content">{ label || children }</div>

                        { Sp }
                        { Fe }

                    </Link>

                </span>

            );

        }

        else {

            return (

                <span className={ CS }>

                    <div
                    
                        className="Button"
                        onClick={ this.OnClick }
                        onBlur={ this.OnBlur }
                        onFocus={ this.OnFocus }
                        ref="button"
                        tabIndex="0"
                        title={ title || label }
                        
                    >

                        { Ic }
                    
                        <div className="ButtonContent" ref="content">{ label || children }</div>

                        { Sp }
                        { Fe }
                    
                    </div>

                </span>

            );

        }

    }

}

Button.propTypes = {

    big: PropTypes.bool,
    className: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    done: PropTypes.bool,
    feather: PropTypes.string,
    hollow: PropTypes.bool,
    href: PropTypes.string,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    label: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    loading: PropTypes.bool,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    small: PropTypes.bool,
    target: PropTypes.string,
    title: PropTypes.string,
    to: PropTypes.string

};

Button.defaultProps = {

    big: false,
    className: "",
    color: "blue",
    disabled: false,
    done: false,
    feather: "",
    hollow: false,
    href: "",
    id: "",
    label: "",
    loading: false,
    onBlur: () => {},
    onClick: () => {},
    onFocus: () => {},
    small: false,
    target: "_blank",
    title: "",
    to: ""

};

export default Button;