
/*!
 *  Image(s) form field.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field is disabled.
 *  @prop string id - Field ID.
 *  @prop string label - Field label. Overrides children.
 *  @prop boolean multiple - Whether to allow multiple image uploads.
 *  @prop function onChange - Callback function.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./imagefield.scss";

import Globals from "Class/Globals";
import Mailchimp from "Class/Mailchimp";
import { ObjectCompare, RandomToken } from "Functions";

import Icon from "Components/Layout/Icon";
import LoadImage from "Components/Layout/LoadImage";
import Spinner from "Components/Feedback/Spinner";

class ImageField extends React.Component {

    constructor( props ) {

        super( props );

        this.Dialog = false;
        this.Mounted = false;
        this.Request = false;
        this.RequestDelay = 300;
        this.RequestTimer = false;
        this.UploadDialog = false;

        this.state = {

            error: false,
            loading: false,
            src: [],
            value: []

        };

    }

    /**
     * Set initial value.
     *   
     * @return void
     */

    componentDidMount() {

        this.Mounted = true;

        const { value } = this.props;

        this.SetValue( value, true );

    }

    /**
     * Update value.
     *   
     * @return void
     */

    UNSAFE_componentWillReceiveProps( nextProps ) {

        const { disabled, value } = nextProps;

        if ( disabled && disabled !== this.props.disabled && this.Dialog ) {

            if ( this.UploadDialog ) {

                this.UploadDialog.OnAbort();

            }

            Globals.DialogDestroy( this.Dialog );

        }

        if ( !ObjectCompare( value, this.state.value ) ) {

            this.SetValue( value, true );

        }

    }

    /**
     * Destroy dialog on unmount.
     *   
     * @return void
     */

    componentWillUnmount() {

        this.Mounted = false;

        if ( this.UploadDialog ) {

            this.UploadDialog.OnAbort();

        }

        Globals.DialogDestroy( this.Dialog );

    }

    /**
     * Clear field.
     * 
     * @param object e - The event object.
     * @param boolean noCallback - Whether to skip onChange().
     *   
     * @return void
     */

    Clear = ( e, noCallback = false ) => {

        const { disabled, id, onChange } = this.props;

        if ( disabled || !this.Mounted ) {

            return;

        }

        if (e) {

            e.stopPropagation();

        }

        const E1 = [];
        const E2 = [];

        if ( !noCallback ) {

            onChange( e, E1, E2, id );

        }

        this.setState( {
            
            src: E2,
            value: E1
            
        } );

    }

    /**
     * Get the input field value.
     *   
     * @return string - Update CTA when empty, otherwise comma-seperated image names.
     */

    ImageName = () => {

        const { gallery, multiple } = this.props;
        const { loading, src, value } = this.state;
        const Names = [];

        if ( loading ) {

            return "Laddar...";

        }

        value.forEach( ( id, index )  => {

            const { name } = src[ index ] || {};

            if ( !name ) {

                return;

            }

            Names.push( name );

        } );

        if ( !Names.length ) {

            return gallery ? ( multiple ? "Välj bilder" : "Välj bild" ) : ( multiple ? "Ladda upp bilder" : "Ladda upp bild" );

        }

        return Names.join( ", " );

    }

    /**
     * Output image preview(s), or feather icon when empty.
     *   
     * @return JSX - image preview.
     */

    ImagePreview = () => {

        const { error, loading, src, value } = this.state;
        const Preview = [];

        if ( loading ) {

            return (

                <div className="ImageFieldPreview Loading" key="loading">
                
                    <Spinner
                    
                        size={ 24 }

                    />
                
                </div>

            );

        }

        if ( error ) {

            return (

                <div className="ImageFieldPreview Error" key="error">
                
                    <Icon
                    
                        feather="AlertCircle"
                        size={ 24 }
                        
                    />
                
                </div>

            );

        }

        value.forEach( ( id, index ) => {

            const { thumbnail_url } = src[ index ] || {};

            if ( !id || !thumbnail_url ) {

                return;

            }

            Preview.push( <LoadImage key={ id } src={ thumbnail_url } /> );

        } );

        if ( !Preview.length ) {

            return (

                <div className="ImageFieldPreview Empty" key="empty">
                
                    <Icon

                        feather="Image"
                        size={ 24 }
                        
                    />
                
                </div>

            );

        }

        return (

            <div
            
                className="ImageFieldPreview Filled"
                onClick={ this.Clear }
                
            >
            
                { Preview }

                <Icon

                    feather="X"
                    size={ 24 }
                    
                />

            </div>

        );

    }

    /**
     * Callback when the field is clicked.
     *   
     * @return void
     */

    OnBrowse = () => {

        const { disabled, gallery, multiple } = this.props;
        const { value } = this.state;

        if ( disabled ) {

            return;

        }

        this.Dialog = Globals.DialogCreate( gallery ? {

            title: multiple ? "Välj bilder" : "Välj bild",
            type: "gallery",
            props: {

                ref: d => this.UploadDialog = d,
                multiple: multiple,
                onClose: this.OnUploadClose,
                onSelect: this.OnUpload,
                selected: value

            }

        } : {

            title: multiple ? "Ladda upp bilder" : "Ladda upp bild",
            type: "upload",
            props: {

                accept: [ "image/gif", "image/jpeg", "image/png" ],
                ref: d => this.UploadDialog = d,
                multiple: multiple,
                onClose: this.OnUploadClose,
                onDone: this.OnUpload,
                onError: this.OnError

            }

        } );

    }

    /**
     * Trigger onChange when the field is updated.
     * 
     * @param array inputIds - Optional input ids value.
     * @param array inputObjs - Optional input objects value.
     * @param boolean noCallback - Whether to skip onChange().
     *   
     * @return void
     */

    OnChange = ( inputIds, inputObjs, noCallback = false ) => {

        const { id, onChange } = this.props;
        const { src, value } = this.state;
        const Ids = inputIds || value;
        const Srcs = inputObjs || src;

         // Block value update to avoid having to load file info again.
        if ( !noCallback ) {

            setTimeout( () => {

                onChange( null, Ids, Srcs, id );

            }, 0 );

        }
        
    }

    /**
     * Catch error messages from the file upload dialog.
     *   
     * @return void
     */

    OnError = ( error ) => {

        if ( !this.Mounted ) {

            return;

        }

        this.setState( { error } );

    }

    /**
     * Callback when uploads finish.
     * 
     * @param array inputIds - Optional input ids value.
     * @param array inputObjs - Optional input objects value.
     *   
     * @return void
     */

    OnUpload = ( uploadIds, uploadObjs ) => {

        const { disabled, gallery } = this.props;

        if ( disabled || !this.Mounted ) {

            return;

        }

        if ( !uploadObjs.length ) {

            this.setState( { error: gallery ? "Inga valda bilder." : "Inga uppladdade bilder." } );

        }

        else {

            this.setState( {
                
                value: uploadIds,
                src: uploadObjs
                
            } );

            Globals.DialogDestroy( this.Dialog );

            this.OnChange( uploadIds, uploadObjs );

        }

    }

    /**
     * Abort all uploads when the dialog is closed.
     *   
     * @return void
     */

    OnUploadClose = () => {

        if ( this.UploadDialog ) {

            this.UploadDialog.OnAbort();

        }

    }

    /**
     * Reset to inital state.
     *   
     * @return void
     */

    Reset = () => {

        const { value } = this.props;

        this.SetValue( value );

    }

    /**
     * Load image info from image ids.
     * 
     * @param array images - An array containing image ids.
     * @param boolean noCallback - Whether to skip onChange().
     *   
     * @return void
     */

    SetValue = ( images, noCallback = false ) => {

        if ( !this.Mounted ) {

            return;

        }

        if ( !images.length || !images[0] || typeof images[0] === "string" ) {

            return this.Clear( null, noCallback );

        }

        this.setState( {
            
            error: false,
            loading: true,
            value: []
            
        } );

        clearTimeout( this.RequestTimer );

        this.RequestTimer = setTimeout( () => {

            if ( !this.Mounted ) {

                return;

            }

            const Request = this.Request = RandomToken();

            Mailchimp.MediaMeta( images, ( response ) => {
                
                if ( !this.Mounted || Request !== this.Request ) {

                    return;

                }

                const { images: src, error } = response;
                const Src = src || [];

                if ( error ) {

                    this.setState( {
                        
                        error,
                        loading: false,
                        src: [],
                        value: []
                        
                    } );

                }

                else {

                    this.setState( {
                        
                        loading: false,
                        src: Src,
                        value: images
                        
                    } );

                }

                this.OnChange( images, Src, noCallback );

            } );

        }, this.RequestDelay );

    }

    /**
     * Get the (first) image name as the field value.
     *   
     * @return string - The first image name.
     */

    Value = () => {

        return this.ImageName() || "";

    }

    render() {

        const { big, className, disabled, gallery, label } = this.props;
        const { error } = this.state;
        const CA = [ "Field", "ImageField" ];

        if ( big ) CA.push( "Big" );
        if ( className ) CA.push( className );
        if ( disabled ) CA.push( "Disabled" );

        const CS = CA.join( " " );

        return (

            <div className={ CS } onClick={ this.OnBrowse }>
            
                {
                
                    label ? (
                    
                        <label>
                    
                            { label }
                        
                        </label>
                    
                    ) : ""

                }

                { this.ImagePreview() }

                <div className="Input">
                
                    <span>{ error || this.ImageName() }</span>

                    <Icon feather={ gallery ? "Folder" : "Upload" } />
                
                </div>
            
            </div>

        );
        
    }

}

ImageField.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.bool,
    gallery: PropTypes.bool,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    label: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    multiple: PropTypes.bool,
    onChange: PropTypes.func

};

ImageField.defaultProps = {

    className: "",
    disabled: false,
    gallery: true,
    id: "",
    label: "",
    multiple: false,
    onChange: () => {},
    value: []

};

export default ImageField;