
/**!
 *  Error message
 *
 *  @prop string className - Append a class name.
 *  @prop string label - Error message.
 *
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./error.scss";

import Button from "Components/UI/Button";

// Import ErrorIcon from Icon.
import Icon from "Components/Layout/Icon";

class Error extends React.Component {

    render() {

        const { button, children, className, label, onClick, overlay } = this.props;
        const CA = [ "Error" ];

        if ( className ) CA.push( className );
        if ( !label && !children )  CA.push( "Empty" );
        if ( overlay ) CA.push( "OverlayError" );

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                <Icon className="ErrorIcon" feather="AlertCircle" />
            
                <span>{ label || children }</span>

                { button ? <Button
                
                    className="ErrorButton"
                    label={ button }
                    onClick={ onClick }
                    small={ true }
                
                /> : "" }
            
            </div>

        );

    }

}

Error.propTypes = {

    button: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    overlay: PropTypes.bool

};

Error.defaultProps = {

    button: "",
    className: "",
    label: "",
    onClick: () => {},
    overlay: false

};

export default Error;