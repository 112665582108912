
/*!
 *  Simple text item
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the item should be disabled.
 *  @prop string id - Item ID.
 *  @prop function onClick - Callback for when the item is clicked.
 *  @prop string title - The item title tag.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";

class Item extends React.Component {

    /**
     * Callback for when the item is clicked.
     * 
     * @param object e - The click event.
     * 
     * @return void 
     */

    OnClick = (e) => {

        const { disabled, id, onClick } = this.props;

        if ( disabled ) {

            return;

        }

        onClick( e, id );

    }

    render() {

        const { children, className, disabled, label, title } = this.props;
        const CA = [ "Item" ];

        if ( className ) CA.push( className );
        if ( disabled ) CA.push( "Disabled" );

        const CS = CA.join( " " );

        return (

            <div
            
                className={ CS }
                onClick={ this.OnClick }
                title={ title }
                
            >
            
                { label || children }
            
            </div>

        );

    }

}

Item.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string

};

Item.defaultProps = {

    className: "",
    disabled: false,
    id: "",
    label: "",
    onClick: () => {},
    title: ""

};

export default Item;