
/*!
 *  Color palette form field.
 *
 *  @prop string className - Append a class name.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./palettefield.scss";

import SelectField from "Components/UI/Field/SelectField";

class PaletteField extends React.Component {

    /**
     * Get the field value.
     * 
     * @return object|boolean - Field value.
     */

    Value = () => {

        const { field } = this.refs;

        return field ? field.Value() : false;

    }

    render() {

        const { className, colors } = this.props;
        const CA = [ "PaletteField" ];

        if ( className ) {

            CA.push( className );

        }

        const CS = CA.join( " " );
        const Options = {};

        colors.forEach( ( [ code, name, slug ] ) => {

            Options[ slug || code ] = (

                <div className="PaletteFieldColorItem">

                    <div className="PaletteFieldColor" style={{ backgroundColor: code }} />

                    { name }

                </div>

            );

        } );

        return (

            <SelectField

                { ...this.props }
            
                className={ CS }
                options={ Options }
                ref="field"
            
            />

        );

    }

}

PaletteField.propTypes = {

    className: PropTypes.string,
    colors: PropTypes.array

};

PaletteField.defaultProps = {

    className: "",
    colors: []

};

export default PaletteField;
