
/**!
 *  Scrollable container.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./scrollview.scss";

import { ObjectCompare } from "Functions";

class ScrollView extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            scrollDown: true,
            scrollUp: false

        }

    }

    /**
     *  Set appearance on mount.
     *  
     *  @return void.
     */

    componentDidUpdate() {

        this.OnScroll();

    }

    /**
     *  Callback when the container is scolled. Toggles the visibility of the
     *  shadows at the top and bottom of the container.
     * 
     *  @param object e - The event object.
     *  
     *  @return void.
     */

    OnScroll = (e) => {

        const { id, onScroll } = this.props;
        const { content } = this.refs;

        if ( !content ) {

            return;

        }

        const Scrolled = content.scrollTop;
        const Height = content.scrollHeight - content.offsetHeight;
        const State = {

            scrollDown: Height > Math.max( Scrolled, 20 ),
            scrollUp: !!Scrolled

        };

        if ( !ObjectCompare( State, this.state ) ) {

            this.setState( State );

        }

        onScroll( e, Scrolled, Height, id );

    }

    /**
     *  Callback when the scroll wheel is used within the container.
     * 
     *  @param object e - The event object.
     *  
     *  @return void.
     */

    OnWheel = (e) => {

        const { id, onWheel } = this.props;
        const { content } = this.refs;
        const { deltaY, detail, wheelDelta } = e;

        if ( !content ) {

            return;

        }

        const Delta = deltaY || wheelDelta || -detail;
        const Scrolled = content.scrollTop;
        const Height = content.scrollHeight - content.offsetHeight;

        onWheel( e, Scrolled, Height, Delta, id );

    }

    render() {

        const { children, className } = this.props;
        const { scrollDown, scrollUp } = this.state;
        const CA = [ "ScrollView" ];

        if ( className ) CA.push( className ); 
        if ( scrollDown ) CA.push( "ScrollDown" );
        if ( scrollUp ) CA.push( "ScrollUp" );

        const CS = CA.join( " " );

        return (

            <div className={ CS } >

                <div
                
                    className="ScrollViewContent"
                    onScroll={ this.OnScroll }
                    onWheel={ this.OnWheel }
                    ref="content"
                    
                >

                    <div className="ScrollViewContentWrapper">

                        { children }

                    </div>

                </div>

            </div>

        );

    }

}

ScrollView.propTypes = {

    className: PropTypes.string,
    id: PropTypes.string,
    onScroll: PropTypes.func,
    onWheel: PropTypes.func,
    title: PropTypes.string

};

ScrollView.defaultProps = {

    className: "",
    id: "",
    onScroll: () => {},
    onWheel: () => {},
    title: ""

};

export default ScrollView;