
/*!
 *  List form field.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the list field should be disabled.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop function onEdit - Callback when a list items edit button is clicked.
 *  @prop function onHover - Callback when a list item is hovered.
 *  @prop function onRemove - Callback when a list items remove button is clicked.
 *  @prop function onSort - Callback when an item is moved.
 *  @prop function onSortEnd - Callback when an item is released.
 *  @prop function onSortStart - Callback when an item is grabbed.
 *  @prop array value - Field items.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./listfield.scss";

import IconButton from "Components/UI/IconButton";

class ListField extends React.Component {

    constructor( props ) {

        super( props );

        this.Sorting = false;
        this.SortingGrid = false;
        this.SortingIndex = -1;

        this.state = {

            hover: -1,
            sorting: false

        };

    }

    /**
     * Stop an event from propagating and its' default behaviour.
     * 
     * @param object e - The event object.
     *   
     * @return void
     */

    Block = (e) => {

        e.stopPropagation();
        e.preventDefault();

    }

    /**
     * Callback when the an items edit button is clicked.
     * 
     * @param object e - The event object.
     * @param integer index - The item index.
     * @param boolean switchTab - Whether to switch tab in the parent editor.
     *   
     * @return void
     */

    OnEditItem = ( e, index, switchTab = true ) => {

        this.Block(e);

        const { disabled, id, onEdit } = this.props;

        if ( disabled ) {

            return;

        }

        onEdit( e, index, id, switchTab );

    }

    /**
     * Callback when the an items remove button is clicked.
     * 
     * @param object e - The event object.
     * @param integer index - The item index.
     *   
     * @return void
     */

    OnRemoveItem = ( e, index ) => {

        this.Block(e);

        const { disabled, id, onRemove } = this.props;

        if ( disabled ) {

            return;

        }

        onRemove( e, index, id );

    }

    /**
     * Callback when the user stops dragging an item.
     * 
     * @param object e - The event object.
     *   
     * @return void
     */

    OnSortEnd = (e) => {

        const { id, onSort, onSortEnd } = this.props;
        const { sorting } = this.state;
        const [ Target ] = this.Sorting;

        if ( this.SortingIndex >= 0 && sorting !== this.SortingIndex ) {

            onSort( e, sorting, this.SortingIndex, id );

        }

        onSortEnd( e, id );

        this.Sorting = false;
        this.SortingGrid = false;
        this.SortingIndex = -1;

        Target.style.transform = "translate3d(0,0,0)";

        this.setState( {
            
            hover: -1,
            sorting: false
            
        } );

        window.removeEventListener( "mousemove", this.OnSortMove );
        window.removeEventListener( "mouseup", this.OnSortEnd );

    }

    /**
     * Callback when the user drags an item.
     * 
     * @param object e - The event object.
     *   
     * @return void
     */

    OnSortMove = (e) => {

        const { hover } = this.state;
        const { pageX, pageY } = e;
        const [ Target, OriginX, OriginY ] = this.Sorting;
        const X = pageX - OriginX;
        const Y = pageY - OriginY;
        const Items = this.SortingGrid.length;

        Target.style.transform = `translate3d(${X}px,${Y}px,0)`;

        this.SortingIndex = Items;

        for ( let i = 0; i < Items; i++ ) {

            if ( Y < this.SortingGrid[i] ) {

                this.SortingIndex = i;
                break;

            }

        }

        if ( this.SortingIndex !== hover ) {

            this.setState( { hover: this.SortingIndex } );

        }

    }

    /**
     * Callback when the user starts dragging an item.
     * 
     * @param object e - The event object.
     * @param integer index - The item index.
     *   
     * @return void
     */

    OnSortStart = ( e, index ) => {

        const { disabled, id, onSortStart } = this.props;
        const { currentTarget, pageX, pageY } = e;
        const { items } = this.refs;

        if ( disabled || !items || e.button !== 0 ) {

            return;

        }

        const Offset = currentTarget.offsetTop;

        this.Sorting = [ currentTarget, pageX, pageY ];
        this.SortingGrid = [];

        items.childNodes.forEach( item => {

            this.SortingGrid.push( item.offsetTop - Offset );

        } );

        onSortStart( e, index, id );

        this.setState( { sorting: index } );

        window.addEventListener( "mousemove", this.OnSortMove );
        window.addEventListener( "mouseup", this.OnSortEnd );

    }

    /**
     * Return the first item as the list fields value.
     * 
     * @return string - The first item.
     */

    Value = () => {

        const { value } = this.props;

        return value.length ? value[0] : "";

    }

    render() {

        const {
            
            className,
            disabled,
            highlight,
            label,
            lock,
            onHover,
            value
            
        } = this.props;

        const { hover, sorting } = this.state;
        const CA = [ "ListField" ];

        if ( className ) {

            CA.push( className );

        }

        if ( disabled ) {

            CA.push( "Disabled" );

        }

        const CS = CA.join( " " );
        const Items = [];

        value.forEach( ( item, index ) => {

            const ItemCA = [ "ListFieldItem" ];
            const ItemLocked = lock.indexOf( parseInt( index, 10 ) ) >= 0;

            if ( index === sorting ) {

                ItemCA.push( "Active" );

            }

            if ( index === highlight ) {

                ItemCA.push( "Highlight" );

            }

            if ( index === hover ) {

                ItemCA.push( "Hover" );

            }

            else if ( index === value.length - 1 && index < hover ) {

                ItemCA.push( "HoverLast" );

            }

            if ( ItemLocked ) {

                ItemCA.push( "Locked" );

            }

            const ItemCS = ItemCA.join( " " );

            Items.push(

                <div
                
                    className={ ItemCS }
                    key={ index }
                    onClick={ ItemLocked ? e => this.OnEditItem( e, index, false ) : null }
                    onMouseDown={ ItemLocked ? null : e => this.OnSortStart( e, index ) }
                    onMouseEnter={ e => onHover( e, index ) }
                    onMouseLeave={ e => onHover( e, false ) }
                
                >

                    { typeof item == "object" ? item[0] || item : item }

                    <div className="ListFieldItemIcons">

                        <IconButton
                        
                            className="ListFieldItemIcon"
                            disabled={ disabled }
                            feather="Edit2"
                            onClick={ e => this.OnEditItem( e, index ) }
                            onMouseDown={ this.Block }
                            
                        />

                        <IconButton
                        
                            className="ListFieldItemIcon"
                            disabled={ disabled || ItemLocked }
                            feather="X"
                            onClick={ e => this.OnRemoveItem( e, index ) }
                            onMouseDown={ this.Block }
                            
                        />

                    </div>

                </div>

            );

        } );
        
        return (
        
            <div className={ CS }>

                { label ?  <label>

                    { label }

                </label> : "" }

                <div className="ListFieldItems" ref="items">

                    { Items }

                </div>

            </div>

        );

    }

}

ListField.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.bool,
    highlight: PropTypes.number,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    label: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    lock: PropTypes.array,
    onEdit: PropTypes.func,
    onHover: PropTypes.func,
    onRemove: PropTypes.func,
    onSort: PropTypes.func,
    onSortEnd: PropTypes.func,
    onSortStart: PropTypes.func,
    value: PropTypes.array

};

ListField.defaultProps = {

    className: "",
    disabled: false,
    highlight: -1,
    id: "",
    label: "",
    lock: [],
    onEdit: () => {},
    onHover: () => {},
    onRemove: () => {},
    onSort: () => {},
    onSortEnd: () => {},
    onSortStart: () => {},
    value: []

};

export default ListField;