
import React from "react";
import PropTypes from "prop-types";

import Mailchimp from "Class/Mailchimp";

import Error from "Components/Feedback/Error";
import Spinner from "Components/Feedback/Spinner";
import TemplateEditor from "Components/View/TemplateEditor";

class ViewTemplateEdit extends React.Component {

    constructor( props ) {

        super( props );

        this.Mounted = false;

        this.state = {

            id: 0,
            error: false,
            loading: false,
            template: false

        };

    }

    componentDidMount() {

        this.Mounted = true;

        const { id } = this.props;

        this.Load( id );

    }

    componenDidUpdate() {

        if ( !this.Mounted ) {

            return;

        }

        const { id: id1 } = this.props;
        const { id: id2 } = this.state;

        if ( id1 !== id2 ) {

            this.Load( id2 );

        }

    }

    componentWillUnmount() {

        this.Mounted = false;

    }

    Load = ( id ) => {

        if ( !this.Mounted || !id ) {

            return;

        }

        this.setState( {

            id,
            error: false,
            loading: true

        } );

        Mailchimp.Template( id, template => {

            if ( !this.Mounted ) {

                return;

            }

            if ( !template ) {

                this.setState( {

                    error: true,
                    loading: false

                } );

            } 

            else {

                this.setState( {

                    loading: false,
                    template

                } );

            }

        } );

    }

    Reload = () => {

        const { id } = this.state;

        this.Load( id );

    }

    render() {

        const { copy } = this.props;
        const { error, loading, template } = this.state;
        let Content;

        if ( loading ) {

            Content = <Spinner className="PrimarySpinner" overlay={ true } />;

        }

        else if ( error || !template ) {

            Content = <Error
            
                button="Försök igen"
                label="Mallen kunde inte laddas"
                onClick={ this.Reload }
                overlay={ true }

            />;

        }

        else {

            const { external, id, name, modules, utm, utmShow } = template;
            const Template = copy ? {

                modules

            } : {

                external,
                id,
                name,
                modules,
                utm,
                utmShow

            };

            Content = <TemplateEditor template={ Template } />;

        }

        return (
        
            <div className="TemplateEditor">{ Content }</div>

        );

    }

}

ViewTemplateEdit.propTypes = {

    copy: PropTypes.bool,
    id: PropTypes.number

};

ViewTemplateEdit.defaultProps = {

    copy: false,
    id: 0

};

export default ViewTemplateEdit;