
/**!
 *  Overlay dialog view.
 *
 *  @param string className - Append a class name.
 *  @param boolean disabled - Whether the overlay dialog should be disabled.
 *  @param string id - The overlay id.
 *  @param function onClose - Callback for when the overlay dialog is closed.
 *  @param boolean overlayClose - Whether the dialog can be closed by clicking thhe overlay bg.
 *  @param string title - Overlay dialog title.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./overlay.scss";

import Center from "Components/Layout/Center";
import IconButton from "Components/UI/IconButton";

class Overlay extends React.Component {

    /**
     * Callback for when the close button icon is clicked.
     * 
     * @param object e - The click event.
     * 
     * @return void
     */

    OnClose = (e) => {

        e.stopPropagation();

        const { disabled, id, onClose } = this.props;

        if ( disabled ) {

            return;

        }

        onClose( e, id );

    }

    render() {

        const { children, className, disabled, overlayClose, title, width } = this.props;
        const CA = [ "Overlay" ];

        if ( className ) CA.push( className );
        if ( disabled ) CA.push( "Disabled" );

        const CS = CA.join( " " );

        return (

            <Center
            
                className={ CS }
                props={ overlayClose ? { onClick: this.OnClose } : {} }
                width={ width }
                
            >
            
                <div className="OverlayDialog" onClick={ e => e.stopPropagation() }>

                    <div className="OverlayDialogTitle">{ title }</div>

                    <IconButton
                    
                        className="OverlayDialogClose"
                        feather="X"
                        onClick={ this.OnClose }
                        
                    />

                    <div className="OverlayDialogContent">
                    
                        { children }
                    
                    </div>
                
                </div>
            
            </Center>

        );

    }

}

Overlay.propTypes = {

    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    onClose: PropTypes.func,
    title: PropTypes.string,
    width: PropTypes.number

};

Overlay.defaultProps = {

    className: "",
    disabled: false,
    id: "",
    onClose: () => {},
    overlayClose: true,
    title: "",
    width: 340

};

export default Overlay;