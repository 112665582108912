
/*!
 *  Mailchimp template editor.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./templateeditor.scss";

import API from "Class/API";
import Globals from "Class/Globals";
import Mailchimp from "Class/Mailchimp";
import { ArrayMove, RandomToken, StripTags } from "Functions";

import Button from "Components/UI/Button";
import CheckboxField from "Components/UI/Field/CheckboxField";
import Modules from "Components/UI/Modules";
import Spinner from "Components/Feedback/Spinner";
import TabMenu from "Components/UI/TabMenu";
import TemplateEditorForm from "./form.js";
import TemplateEditorPreview from "./preview.js";
import TextField from "Components/UI/Field/TextField";


class TemplateEditor extends React.Component {

    constructor( props ) {

        super( props );

        this.Fields = false;
        this.Set = false;
        this.TabItems = [ "Moduler", "Modul" ];

        this.state = {

            active: -1,
            error: false,
            external: 0,
            id: 0,
            loading: false,
            modules: false,
            moduleTemplates: false,
            name: "",
            publishing: false,
            saving: false,
            tab: "0",
            utm: {},
            utmShow: false

        };

    }

    componentDidMount() {

        this.LoadModules();

    }

    DefaultFields = ( type ) => {

        const { moduleTemplates } = this.state;
        const { fields } = moduleTemplates ? moduleTemplates[ type ] || {} : {};

        return this.DefaultFieldsAppend( fields );

    }

    DefaultFieldsAppend( fields, container = {} ) {

        if ( !fields ) {

            return container;

        }

        for ( let key in fields ) {

            let { children, default: defaultValue, type } = fields[ key ];

            if ( type === "group" || type === "tab" ) {

                this.DefaultFieldsAppend( children, container );

            }

            else {

                container[ key ] = defaultValue || "";

            }

        }

        return container;

    }

    LoadModules = () => {

        this.setState( {

            error: false,
            loading: true

        } );

        API.Request( "templates/modules", {}, response => {

            const { template } = this.props;
            const { error, modules } = response;

            if ( !modules || error ) {

                this.setState( {

                    error: true,
                    loading: false

                } );

            }

            else {

                this.setState( {

                    loading: false,
                    moduleTemplates: modules

                } );

                setTimeout( () => this.SetTemplate( template ), 0 );

            }

        } );

    }

    OnModuleAdd = ( e, type ) => {

        const { modules } = this.state;

        if ( typeof modules !== "object" || modules.length < 2 ) {

            return;

        }

        const Index = modules.length - 1;

        modules.splice( modules.length - 1, 0, {

            type,
            id: RandomToken(),
            fields: this.DefaultFields( type )

        } );

        this.setState( {

            active: Index,
            modules

        } );

        this.SetFields();

    }

    OnModuleEdit = ( e, index, switchTab ) => {

        if ( switchTab ) {
        
            this.setState( {

                active: index,
                tab: "1"

            } );

        }

        else {

            this.setState( {

                active: index

            } );

        }

        this.SetFields();

    }

    OnModuleField = ( e, value, field ) => {

        const { active, modules } = this.state;
        const { fields } = modules[ active ] || {};
        const { type: fieldType } = this.Fields[ field ] || {};

        if ( !fields ) {

            return;

        }

        switch ( fieldType ) {

            case "image":

                fields[ field ] = ( typeof value === "object" && value[0] ) ? value : "";
                break;

            case "wysiwyg":

                fields[ field ] = StripTags( value ) ? value : "";
                break;

            default:
                
                fields[ field ] = value;

        }

        this.setState( { modules } );

    }

    OnModuleRemove = ( e, index ) => {

        Globals.DialogCreate( {

            type: "confirm",
            message: "Är du säker på att du vill ta bort denna innehållsmodul?",
            confirmLabel: "Ta bort modul",
            onConfirm: () => this.Remove( index )

        } );

    }


    OnModuleSort = ( e, from, to ) => {

        const { modules } = this.state;
        const End = modules.length - 1;

        // Don't move header or footer.
        if ( from <= 0 || from >= End || to <= 0 || to > End ) {

            return;

        }

        this.setState( {

            active: to > from ? to - 1 : to,
            modules: ArrayMove( modules, from, to )

        } );

    }

    OnModuleTemplate = ( e, template ) => {

        const { active, modules } = this.state;

        if ( modules[ active ] === undefined ) {

            return;

        }

        modules[ active ].type = template;

        this.setState( { modules } );

    }

    OnName = ( e, name ) => {

        this.setState( { name } );

    }

    OnTab = ( e, tab ) => {

        this.setState( { tab } );

    }

    OnUtm = ( e, value, key ) => {

        const { loading, publishing, utm } = this.state;
        const Utm = utm || {};

        if ( loading || publishing ) {

            return;

        }

        Utm[ key ] = value;

        this.setState( {

            utm: Utm

        } );

    }

    OnUtmShow = ( e, utmShow ) => {

         const { loading, publishing } = this.state;

        if ( loading || publishing ) {

            return;

        }

        this.setState( { utmShow } );

    }

    Remove = ( index ) => {

        const { modules } = this.state;

        modules.splice( index, 1 );

        this.setState( {

            active: -1,    
            modules
            
        } );

    }

    Save = ( publish ) => {

        const { onSave } = this.props;
        const { id, loading, name, modules, publishing, saving, utm, utmShow } = this.state;
        const Publish = publish === true;

        if ( loading || !name || publishing || saving ) {

            return;

        }

        if ( Publish ) {

            this.setState( {

                error: false,
                publishing: true

            } );

        }

        else {

            this.setState( {

                error: false,
                saving: true

            } );

        }

        API.Request( "templates/save", {
            
            template: id,
            name,
            modules,
            publish: Publish,
            utm,
            utmShow

            
        }, response => {

            const { id: savedId, edited, error, external, message } = response;

            if ( error ) {

                this.setState( {

                    error: message || true,
                    publishing: false,
                    saving: false

                } );

            }

            else {

                this.setState( {

                    external: external || 0,
                    id: savedId,
                    publishing: false,
                    saving: false

                } );

                Mailchimp.RegisterTemplate( {

                    edited,
                    external,
                    id: savedId,
                    name,
                    modules

                } );

                onSave( savedId );

            }

        } );

    }

    SavePublish = () => {

        this.Save( true );

    }

    SetFields = () => {

        setTimeout( () => {

            const { active, modules, moduleTemplates } = this.state;

            if ( typeof modules !== "object" || typeof moduleTemplates !== "object" || active < 0 ) {

                return;

            }

            const { type } = modules[ active ] || {};
            const { fields } = moduleTemplates[ type ] || {};

            if ( !fields ) {

                return;

            }

            this.Fields = {};

            this.SetFieldsExtract( fields );

        }, 0 );

    }

    SetFieldsExtract = ( fields ) => {

        for ( let key in fields ) {

            const { children, type } = fields[ key ];

            if ( type === "group" || type === "tab" ) {

                this.SetFieldsExtract( children );

            }

            else {

                this.Fields[ key ] = fields[ key ];

            }

        }

    }

    SetTemplate = ( template ) => {

        let { external, id, name, modules, utm, utmShow } = template || {};

        if ( !modules ) {

            modules = [];

        }

        if ( !modules[0] || modules[0].type !== "header" ) {

            modules.unshift( {

                type: "header",
                id: RandomToken(),
                fields: this.DefaultFields( "header" )

            } );

        }

        if ( !modules.length || modules[ modules.length - 1 ].type !== "footer" ) {

            modules.push( {

                type: "footer",
                id: RandomToken(),
                fields: this.DefaultFields( "footer" )

            } );

        }

        this.setState( {

            external: external || 0,
            id: id || 0,
            name: name || "",
            modules,
            utm,
            utmShow

        } );

        this.SetFields();

    }

    render() {

        const { dc } = Mailchimp.Meta;

        const {
            
            active,
            external,
            loading,
            modules,
            moduleTemplates,
            name,
            publishing,
            saving,
            tab,
            utm,
            utmShow
            
        } = this.state;

        const {

            utmMedium,
            utmSource,
            utmCampaign,
            utmTerm,
            utmContent

        } = utm || {};

        const CA = [ "TemplateEditor", "Tab" + this.TabItems[ tab ] ];
        let Content;

        if ( loading ) {

            Content = <Spinner className="PrimarySpinner" overlay={ true } />

        }

        else {

            Content = [];

            Content.push( <div key="preview" className="TemplateEditorPreview">

                <TemplateEditorPreview
                
                    active={ active }
                    modules={ modules }
                    templates={ moduleTemplates }
                    utm={ utmShow ? utm : false }
                
                />

            </div> );

            Content.push( <div key="modules" className="TemplateEditorModules">

                <TabMenu
                
                    active={ tab }
                    className="TemplateEditorMenu"
                    items={ this.TabItems }
                    onClick={ this.OnTab }
                
                />

                <span>

                    <TextField
                    
                        label="Namn"
                        onChange={ this.OnName }
                        onInput={ this.OnName }
                        placeholder="Namnlös mall"
                        value={ name }
                    
                    />

                    <Modules
                    
                        active={ active }
                        label="Innehållsmoduler"
                        modules={ modules }
                        onAdd={ this.OnModuleAdd }
                        onEdit={ this.OnModuleEdit }
                        onRemove={ this.OnModuleRemove }
                        onSort={ this.OnModuleSort }
                        templates={ moduleTemplates }
                    
                    />

                    <div className="TemplateEditorModuleGroup">

                        <div className="TemplateEditorModuleGroupLabel">UTM-taggar</div>

                        <CheckboxField
                        
                            checked={ utmShow }
                            disabled={ loading || publishing }
                            label="Infoga UTM-taggar"
                            onChange={ this.OnUtmShow }
                        
                        />

                        <TextField
                        
                            disabled={ !utmShow || loading || publishing }
                            id="utmMedium"
                            label="Medium"
                            onChange={ this.OnUtm }
                            value={ utmMedium }
                        
                        />

                        <TextField
                        
                            disabled={ !utmShow || loading || publishing }
                            id="utmSource"
                            label="Source"
                            onChange={ this.OnUtm }
                            value={ utmSource }
                        
                        />

                        <TextField
                        
                            disabled={ !utmShow || loading || publishing }
                            id="utmCampaign"
                            label="Campaign"
                            onChange={ this.OnUtm }
                            value={ utmCampaign }
                        
                        />

                        <TextField
                        
                            disabled={ !utmShow || loading || publishing }
                            id="utmTerm"
                            label="Term"
                            onChange={ this.OnUtm }
                            value={ utmTerm }
                        
                        />

                        <TextField
                        
                            disabled={ !utmShow || loading || publishing }
                            id="utmContent"
                            label="Content"
                            onChange={ this.OnUtm }
                            value={ utmContent }
                        
                        />

                    </div>

                </span>

            </div> );

            Content.push( <div key="form" className="TemplateEditorForm">

                <TabMenu
                
                    active={ tab }
                    className="TemplateEditorMenu"
                    items={ this.TabItems }
                    onClick={ this.OnTab }
                
                />

                <TemplateEditorForm
                
                    active={ active }
                    modules={ modules }
                    onChange={ this.OnModuleField }
                    onChangeTemplate={ this.OnModuleTemplate }
                    templates={ moduleTemplates }
                
                />

            </div> );

            Content.push( <div key="tray" className="TemplateEditorTray">

                <Button
                
                    disabled={ !name || saving }
                    label="Publicera"
                    loading={ publishing }
                    onClick={ this.SavePublish }
                    title="Spara lokalt och publicera"
                
                />

                <Button
                
                    disabled={ !name || publishing  }
                    hollow={ true }
                    label="Spara"
                    loading={ saving }
                    onClick={ this.Save }
                    title="Spara lokalt"
                
                />

                <Button
                    
                    disabled={ !external }
                    label="Visa i Mailchimp"
                    hollow={ true }
                    href={ `https://${dc}.admin.mailchimp.com/templates/edit?id=${external}` }
                    feather="ExternalLink"
                
                />

            </div> );

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                { Content }

            </div>

        );

    }

}

TemplateEditor.propTypes = {

    onSave: PropTypes.func,
    template: PropTypes.oneOfType( [ PropTypes.object, PropTypes.bool ] )

};

TemplateEditor.defaultProps = {

    onSave: () => {},
    template: false

};

export default TemplateEditor;