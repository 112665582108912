
/*!
 *  Mailchimp template editor preview renderer.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";


import Globals from "Class/Globals";

class TemplateEditorPreview extends React.Component {

    constructor( props ) {

        super( props );

        this.Colors = {};
        this.Frame = false;
        this.Mounted = false;
        this.UpdateTimer = false;

        Globals.Colors.forEach( ( [ hex, name, slug ] ) => {

            this.Colors[ slug ] = hex;

        } );

    }

    componentDidMount() {

        this.Mounted = true;

        this.RenderPreview( this.props );

    }

    componentWillUnmount() {

        this.Mounted = false;

    }

    UNSAFE_componentWillReceiveProps( nextProps ) {

        this.RenderUpdate( nextProps );

    }

    OnFrame = ( frame ) => {

        if ( !frame ) {

            return;

        }

        this.Frame = frame;

    }

    Parse = ( base64, fields, index, styles = "", templateName = "", injectUtm ) => {

        const { active, utm } = this.props;

        const Regex = /\|\||&&|==|!=/g;
        
        let Parsed = atob( base64 )
        // Parse conditional content.
        .replace( /\*\|CF:IF:(!?[a-z0-9_|&=!]*)\|\*(.*?)\*\|CF:ENDIF\|\*/gis, ( m, v, c ) => {

            const Operators = v.match( Regex );
            const Values = v.split( Regex );

            let True = true;
            let LastVal;
            let Invalidated = false;

            Values.forEach( ( value, index ) => {

                if ( Invalidated ) {

                    return;

                }

                const Negate = value.substr( 0, 1 ) === "!";
                const Value = Negate ? value.substr(1) : value;
                const Operator = Operators ? Operators[ index - 1 ] : false;
                const IsTrue = Negate ? !fields[ Value ] : !!fields[ Value ];

                if ( !Operator ) {

                    True = IsTrue;

                }

                else if ( Operator === "&&" ) {

                    True = True && IsTrue;
                    Invalidated = !True;

                }

                else if ( Operator === "==" ) {

                    True = LastVal === value;

                }

                else if ( Operator === "!=" ) {

                    True = LastVal !== value;

                }

                else {

                    True = True || IsTrue;

                }

                LastVal = fields[ Value ];

            } );

            return True ? c : "";

        } )
        // Parse variables.
        .replace( /\*\|([a-z0-9_:]*)\|\*/gi, ( m, v ) => {

            let [ Ns, Prop, Field ] = v.split( ":" );
            let FieldValue = fields[ Field ] || "";

            if ( !Prop ) {

                Prop = Ns;
                Ns = "MC";

            }

            if ( Ns === "CF" ) {

                switch ( Prop.toLowerCase() ) {

                    case "base":

                        return Globals.Setting( "ApiUrl" );

                    case "color":

                        return this.Colors[ FieldValue ] || this.Colors[ Field ] || "";

                    case "class":

                        return `module-${index}-${Field}`;

                    case "class_active": {

                        return index === active ? " moduleActive" : "";

                    }

                    case "edit":

                        return `module-${index}-${Field}`;

                    case "field":

                        return FieldValue.replace( /\n/g, "<br />" );

                    case "index":

                        return index;

                    case "preview_styles":

                        return `

                            body {

                                overflow-x: hidden;

                            }

                            body,
                            #bodyCell,
                            #bodyTable {

                                height: auto !important;

                            }
                        
                            #bodyTable {

                                max-width: ${Globals.Width}px;
                                margin: 234px auto;
                                background-color: ${this.Colors.white};
                                box-shadow: 2px 2px 0px rgba( 0, 0, 0, 0.06 );

                            }

                            .moduleActive {

                                position: relative;
                                outline: 2px solid rgba(0, 20, 137, 0.12);

                            }

                            .moduleActive:after,
                            .moduleActive:before {

                                display: block;
                                position: absolute;
                                top: 0;
                                right: 100%;
                                bottom: 0;
                                width: 2000px;
                                background-color: rgba(0, 20, 137, 0.12);
                                box-sizing: border-box;
                                content: "";

                            }

                            .moduleActive:after {

                                left: 100%;

                            }

                            .moduleActive:before {

                                right: 100%;

                            }
                        
                        `;

                    case "src": {

                        while ( FieldValue && typeof FieldValue[0] === "object" ) {

                            FieldValue = FieldValue[0];

                        }

                        return typeof FieldValue === "object" ? FieldValue[1] || "" : ""; 

                    }

                    case "styles":

                        return styles;

                    case "url":

                        return "https://www.cancerfonden.se/";

                    case "variant":

                        return `Block ${index} - ${templateName}`;

                    case "width":

                        return Globals.Width;

                    default:

                        //console.log( Ns, Prop );

                        return "";

                }

            }

            return "";

        } );

        if ( injectUtm && utm ) {

            let Utm = "";

            for ( let key in utm ) {

                let Key = key.replace( /[A-Z]/g, m => { return "_" + m.toLowerCase() } );
                let Val = utm[ key ];

                Utm += Utm ? `&${Key}=${Val}` : `${Key}=${Val}`;

            }

            Parsed = Parsed.replace( /href=("|')?([^"']*)("|')?/gi, ( m, q, u ) => {

                let Url = u.indexOf( "?" ) >= 0 ? u + '&' + Utm : u + '?' + Utm;

                return `href="${Url}"`;

            } );

        }

        return Parsed;

    }

    RenderPreview = ( props ) => {

        if ( !this.Mounted ) {

            return;

        }

        const {  modules, templates } = props;

        if ( typeof modules !== "object" || typeof templates !== "object" ) {

            return;

        }

        let Css = "";

        modules.forEach( ( module, i ) => {

            const { fields, type } = module;
            const { css, name } = templates[ type ] || {};

            if ( !css ) {

                return;

            }

            Css += this.Parse( css, fields, i, "", name );

        } );

        let Html = "";

        modules.forEach( ( module, i ) => {

            const { fields, type } = module;
            const { html, name, repeatable } = templates[ type ] || {};

            if ( !html ) {

                return;

            }

            Html += this.Parse( html, fields, i, Css, name, repeatable );

        } );

        const Stripped = Html.replace( /^.*<html[^>]*>|<\/html>.*$/gis, "" );

        this.Frame.contentDocument.body.parentNode.innerHTML = Stripped;

    }

    RenderUpdate = () => {

        clearTimeout( this.UpdateTimer );

        this.UpdateTimer = setTimeout( () => this.RenderPreview( this.props ), 0 );

    }

    render() {

        return (

            <iframe
            
                className="TemplateEditorPreviewFrame"
                ref={ this.OnFrame }
                title="Förhandsgransking"
                
            ></iframe>

        );

    }

}

TemplateEditorPreview.propTypes = {

    active: PropTypes.number,
    modules: PropTypes.oneOfType( [ PropTypes.array, PropTypes.bool ] ),
    templates: PropTypes.oneOfType( [ PropTypes.object, PropTypes.bool ] ),
    utm: PropTypes.oneOfType( [ PropTypes.object, PropTypes.bool ] )

};

TemplateEditorPreview.defaultProps = {

    active: -1,
    modules: [],
    templates: {},
    utm: false

};

export default TemplateEditorPreview;