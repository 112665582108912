
/*!
 *  File upload interface.
 *
 *  @prop array accept - Allowed mime types.
 *  @prop boolean disabled - Whether the interface should be disabled.
 *  @prop string endpoint - Endpoint (URI) when uploading to the backend server.
 *  @prop boolean multiple - Whether to allow multiple (simultaneous) uploads.
 *  @prop function onChange - Callback for when the uploaders state changes.
 *  @prop function onComplete - Callback for when a file upload is finished. Called regardless of success.
 *  @prop function onUpload - Callback for when a upload is initiated.
 *  @prop boolean show - Whether the interface should be visible.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./fileupload.scss";

import Mailchimp from "Class/Mailchimp";
import Button from "Components/UI/Button";

class FileUpload extends React.Component {

    /**
     *  Keep track on whether the component is mounted.
     *  
     *  @return void.
     */

    componentDidMount() {

        this.Mounted = true;

    }

    /**
     *  Register unmount.
     *  
     *  @return void.
     */

    componentWillUnmount() {

        this.Mounted = false;

    }

    /**
     *  Cancel all active upload processes.
     *  
     *  @return void.
     */

    OnAbort = () => {}

    render() {

        const { dc } = Mailchimp.Meta;

        return (

            <div className="FileUpload">

                <p>Just nu så kan bilder endast laddas upp i Mailchimps Content Studio</p>

                <Button
                        
                    label="Content Studio"
                    href={ `https://${dc}.admin.mailchimp.com/content` }
                    feather="ExternalLink"
                
                />

            </div>

        );

    }

}

FileUpload.propTypes = {

    accept: PropTypes.array,
    appendData: PropTypes.object,
    disabled: PropTypes.bool,
    endpoint: PropTypes.string,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onComplete: PropTypes.func,
    onDone: PropTypes.func,
    onError: PropTypes.func,
    onUpload: PropTypes.func,
    show: PropTypes.bool,

};

FileUpload.defaultProps = {

    accept: [ "image/png", "image/jpeg" ],
    appendData: {},
    disabled: false,
    endpoint: "files/upload",
    multiple: true,
    onChange: () => {},
    onClose: () => {},
    onComplete: () => {},
    onDone: () => {},
    onError: () => {},
    onUpload: () => {},
    show: true

}

export default FileUpload;