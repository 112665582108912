
import React from "react";
import "./templates.scss";

import Mailchimp from "Class/Mailchimp";

import Button from "Components/UI/Button";
import IconItem from "Components/UI/IconItem";
import Link from "Components/UI/Link";
import LoadImage from "Components/Layout/LoadImage";
import Spinner from "Components/Feedback/Spinner";

class ViewTemplates extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            done: false,
            error: false,
            loading: false,
            offset: 0,
            templates: [],
            unpublished: []

        };

    }

    componentDidMount() {

        this.Load();
    
    }

    Item = ( template, index ) => {

        const { dc } = Mailchimp.Meta;
        const { edited, id, local, name, preview } = template;
        const { id: localId } = local || {};
        const CA = [ "TemplateItem" ];

        if ( local ) {

            CA.push( "Editable" );

        }

        else {

            CA.push( "NotEditable" );

        }

        const CS = CA.join( " " );
        
        return (

            <div
            
                className={ CS }
                key={ index }
            
            >

                <div className="TemplateItemPreview">

                    <LoadImage src={ preview } />

                </div>

                <div className="TemplateItemContent">

                    { localId ? <Link
                    
                        to={ `/templates/edit/${localId}` }
                    
                    >

                        <h3>{ name }</h3>
                        
                    </Link> : <h3>{ name }</h3> }

                    { local ? (

                        <p>Senast redigerad { edited }</p>

                    ) : (

                        <p>Denna mall är inte skapad i Mailchimp Editor och kan därför inte redigeras här.</p>

                    ) }

                    <div className="TemplateItemTray">

                        <IconItem
                        
                            disabled={ !localId }
                            label="Redigera"
                            feather="Edit2"
                            to={ `/templates/edit/${localId}` }
                        
                        />

                        <IconItem
                        
                            disabled={ !localId }
                            label="Kopiera"
                            feather="Copy"
                            to={ `/templates/copy/${localId}` }
                        
                        />

                        <IconItem
                        
                            disabled={ !id }
                            label="Öppna i Mailchimp"
                            feather="ExternalLink"
                            href={ `https://${dc}.admin.mailchimp.com/templates/edit?id=${id}` }
                        
                        />

                    </div>

                </div>
            
            </div>

        );

    }

    Load = () => {

        const { offset } = this.state;

        this.setState( {

            error: false,
            loading: true

        } );

        Mailchimp.Templates( response => {

            const { templates, unpublished } = this.state;
            const { error, templates: fetched, unpublished: fetchedUnpublished } = response || {};

            if ( error || typeof fetched !== "object" ) {

                this.setState( {

                    error: true,
                    loading: false

                } );

            }

            else {

                fetched.forEach( template => {

                    Mailchimp.RegisterTemplate( template );

                } );

                fetchedUnpublished.forEach( template => {

                    Mailchimp.RegisterTemplate( template );

                } );


                this.setState( {

                    done: !fetched.length,
                    loading: false,
                    offset: offset + fetched.length,
                    templates: templates.concat( fetched ),
                    unpublished: fetchedUnpublished.length ? fetchedUnpublished : unpublished,

                } );

            }

        }, offset );

    }

    render() {

        const { dc } = Mailchimp.Meta;
        const { error, loading, templates, unpublished } = this.state;
        const CA = [ "Templates" ];
        let Content = "";

        if ( error ) {

            CA.push( "Error" );
            Content = "Error."

        }

        else if ( templates.length || unpublished.length ) {

            const Items = [];
            const Unpublished = [];

            templates.forEach( ( template, index ) => {

                Items.push( this.Item( template, index ) );

            } );

            unpublished.forEach( ( template, index ) => {

                Unpublished.push( this.Item( template, index ) );

            } );

            Content = (

                <div className="TemplatesWrapper">

                    <div className="TemplatesTop">

                        <Button
                        
                            label="Skapa ny"
                            to="/templates/new"
                        
                        />

                        <Button
                        
                            label="Visa i Mailchimp"
                            hollow={ true }
                            href={ `https://${dc}.admin.mailchimp.com/templates` }
                            feather="ExternalLink"
                        
                        />

                    </div>

                    <h2>Opublicerade</h2>

                    <div className="TemplatesItems">

                        { Unpublished }

                    </div>

                    <h2>Publicerade</h2>

                    <div className="TemplatesItems">

                        { Items }

                    </div>

                    <Button
                    
                        label= "Hämta fler"
                        loading={ loading }
                        onClick={ this.Load }
                    
                    />

                </div>

            );

        }

        else if ( loading ) {

            Content = <Spinner className="PrimarySpinner" />

        }

        else {

            Content = "Inga mallar hämtades."

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                { Content }
                
            </div>

        );

    }

}

export default ViewTemplates;