
import React from "react";
import "./logo.scss";
import Cancerfonden from "./cancerfonden.png";

import Link from "Components/UI/Link";
import LoadImage from "Components/Layout/LoadImage";

class Logo extends React.Component {

    render() {

        return <Link
        
            className="Logo"
            to="/"
        
        >

            <LoadImage src={ Cancerfonden } />

        </Link>

    }

}

export default Logo;