
/*!
 *  Text form field.
 *
 *  @prop boolean big - Whether the field should be big.
 *  @prop string className - Append a class name.
 *  @prop boolean disabled - Whether the field should be disabled.
 *  @prop boolean error - Whether this field has an erroneous value.
 *  @prop string feather - Optional. Feather icon src.
 *  @prop string id - Field ID.
 *  @prop string label - Field label.
 *  @prop integer maxLength - Maximum number of characters. 0 = unlimited.
 *  @prop function onBlur - Callback for when the field loses focus.
 *  @prop function onChange - Callback for when the field value has changed.
 *  @prop function onEnter - Callback for when the Enter key is pressed.
 *  @prop function onFocus - Callback for when the field gains focus.
 *  @prop function onInput - Callback for when the field value changes.
 *  @prop string placeholder - Placeholder when empty.
 *  @prop string return - Which variable type should be returned: float, integer, text (default).
 *  @prop string token - Set a custom token identifier for this field.
 *  @prop string type - Input field type: text, email etc...
 *  @prop string|number value - Field value.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./textfield.scss";

import { RandomToken } from "Functions";

import Icon from "Components/Layout/Icon";
import Spinner from "Components/Feedback/Spinner";

class TextField extends React.Component {

    constructor( props ) {

        super( props );

        this.FocusValue = false;
        this.Token = RandomToken();

        this.state = {

            focus: false,
            value: ""

        };

    }

    /**
     * Set initial value.
     * 
     * @return void
     */

    componentDidMount() {

        const { token, value } = this.props;
        const Value = this.ParseValue( value );

        if ( token ) {

            this.Token = token;

        }

        this.setState( { value: Value } );

    }

    /**
     * Update value.
     * 
     * @return void
     */

    UNSAFE_componentWillReceiveProps( nextProps ) {

        const { token, value } = nextProps;

        if ( token && token !== this.Token ) {

            this.Token = token;
            this.forceUpdate();

        }

        if ( value !== this.props.value ) {

            const Value = this.ParseValue( value );
            this.setState( { value: Value } );

        }

    }

    /**
     * Set focus on this field.
     * 
     * @return void
     */

    Focus = () => {

        const { input } = this.refs;

        if ( input ) {

            input.focus();

        }

    }

    /**
     * Callback for when the field loses focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnBlur = (e) => {

        const { id, onBlur, onChange } = this.props;
        const Value = this.ParseValue( e.currentTarget.value );

        if ( this.FocusValue !== Value ) {

            onChange( e, Value, id );

        }

        onBlur( e, Value, id );

        this.setState( { focus: false } );

    }

    /**
     * Callback for when the field gains focus.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnFocus = (e) => {

        const { id, onFocus } = this.props;
        const Value = this.ParseValue( e.currentTarget.value );

        this.FocusValue = Value;

        onFocus( e, Value, id );

        this.setState( { focus: true } );

    }

    /**
     * Callback for when the user inputs a new value into the field.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnInput = (e) => {

        const { disabled, id, onInput } = this.props;

        if ( disabled ) {

            return;

        }

        const Value = this.ParseValue( e.currentTarget.value );

        onInput( e, Value, id );

        this.setState( {
            
            value: Value
            
        } );

    }

    /**
     * Insert a variable key into the field.
     * 
     * @param object e - The event object.
     * @param string key - The variable key.
     * 
     * @return void
     */

    OnInsert = ( e, key ) => {

        const { id, onChange } = this.props;
        const { input } = this.refs;

        if ( !input ) {

            return;

        }

        const Notation = `@{${key}}`;
        const Value = input.value;

        if ( !Value ) {

            input.value = Notation;

        }

        // IE
        else if ( document.selection ) {

            input.focus();

            const Selection = document.selection.createRange();

            Selection.text = Notation;

        }

        // Others
        else if ( input.selectionStart || input.selectionStart === "0" ) {

            const S = input.selectionStart;
            const E = input.selectionEnd;
            
            input.value = Value.substring( 0, S ) + Notation + Value.substring( E, Value.length );
            input.selectionStart = input.selectionEnd = S + Notation.length;
            input.focus();

        }

        const Set = input.value;

        onChange( e, Set, id );

        this.setState( { value: Set } );

    }

    /**
     * Stop key down events from propagating to avoid unintentional navigation.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnKeyDown = (e) => {

        e.stopPropagation();

    }

    /**
     * Listen for when the Enter key is pressed.
     * 
     * @param object e - The event object.
     * 
     * @return void
     */

    OnKeyUp = (e) => {

        const { disabled, id, onEnter } = this.props;

        if ( e.which === 13 && !disabled ) {

            const Value = this.ParseValue( e.currentTarget.value );

            onEnter( e, Value, id );

        }

    }

    /**
     * Parse the field value into desired return type.
     * 
     * @param string value - The raw value.
     * 
     * @return mixed - The parsed value.
     */

    ParseValue = ( value ) => {

        const { maxLength } = this.props;
        let Return, Value = String( value );

        if ( maxLength ) {

            Value = Value.substr( 0, maxLength );

        }

        switch ( this.props.return ) {

            case "float":

                Value = Value.replace( /[^\d.]/g, "" );
                Return = parseFloat( Value );

                return isNaN( Return ) ? 0 : Return;

            case "int":
            case "integer":

                Value = Value.replace( /[^\d]/g, "" );
                Return = parseInt( Value, 10 );

                return isNaN( Return ) ? 0 : Return;

            default:

                return Value;

        }

    }

    /**
     * Reset to inital state.
     * 
     * @return void
     */

    Reset = () => {

        const Value = this.ParseValue( this.props.value );

        this.setState( { value: Value } );

    }

    /**
     * Get the field value.
     * 
     * @return string - The field value.
     */

    Value = () => {

        return this.ParseValue( this.state.value );

    }

    render() {

        const {
            
            big,
            children,
            className,
            disabled,
            error,
            feather,
            label,
            loading,
            placeholder,
            type
            
        } = this.props;

        const { focus, value } = this.state;
        const CA = [ "Field", "TextField" ];

        if ( big ) CA.push( "Big" );
        if ( className ) CA.push( className );
        if ( disabled ) CA.push( "Disabled" );
        if ( error ) CA.push( "Error" );
        if ( focus ) CA.push( "Focus" );
        if ( value ) CA.push( "HasValue" );

        const CS = CA.join( " " );
        let RightSide = "";

        if ( loading ) {

            RightSide = <Spinner className="TextFieldSpinner" size={ 18 } />;

        }

        else if ( feather ) {

            RightSide = <Icon className="FieldIcon" feather={ feather } />;

        }

        return (

            <div className={ CS }>

                { label ?  <label htmlFor={ this.Token }>

                    { label }

                </label> : "" }

                <div className="InputWrapper">

                    <input
                    
                        className="Input"
                        disabled={ disabled }
                        id={ this.Token }
                        onBlur={ this.OnBlur }
                        onChange={ () => {} }
                        onFocus={ this.OnFocus }
                        onInput={ this.OnInput }
                        onKeyDown={ this.OnKeyDown }
                        onKeyUp={ this.OnKeyUp }
                        onMouseDown={ e => e.stopPropagation() }
                        placeholder={ placeholder }
                        ref="input"
                        type={ type }
                        value={ value }
                    
                    />

                    { RightSide }

                </div>

                { children }

            </div>

        );

    }

}

TextField.propTypes = {

    big: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    feather: PropTypes.string,
    id: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] ),
    label: PropTypes.oneOfType( [ PropTypes.string, PropTypes.object ] ),
    loading: PropTypes.bool,
    maxLength: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onFocus: PropTypes.func,
    onInput: PropTypes.func,
    placeholder: PropTypes.string,
    return: PropTypes.string,
    token: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType( [ PropTypes.string, PropTypes.number ] )

};

TextField.defaultProps = {

    big: false,
    className: "",
    disabled: false,
    error: false,
    feather: "",
    id: "",
    label: "",
    loading: false,
    maxLength: 0,
    onAdjust: () => {},
    onBlur: () => {},
    onChange: () => {},
    onEnter: () => {},
    onFocus: () => {},
    onInput: () => {},
    placeholder: "",
    return: "text",
    token: "",
    type: "text",
    value: ""

};

export default TextField;