
import React from "react";
import "./header.scss";

import Globals from "Class/Globals";
import Mailchimp from "Class/Mailchimp";

import Icon from "Components/Layout/Icon";
import Item from "Components/UI/Item";
import Link from "Components/UI/Link";
import LoadImage from "Components/Layout/LoadImage";
import Logo from "Components/Layout/Logo";
import Sticky from "Components/Layout/Sticky";

class Header extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            expand: false

        };

    }

    componentDidMount() {

        Globals.Listen( "var-path", this.OnNavigation );

    }

    componentWillUnmount() {

        Globals.Remove( "var-path", this.OnNavigation );

    }

    OnNavigation = () => {

        this.setState( {

            expand: false

        } );

    }

    OnToggle = () => {

        const { expand } = this.state;

        this.setState( {

            expand: !expand

        } );

    }

    render() {

        if ( !Mailchimp.Authed() ) {

            return "";

        }

        const { expand } = this.state;
        const CA = [ "Header" ];

        if ( expand ) {

            CA.push( "Expand" );

        }

        const CS = CA.join( " " );

        const { accountname, dc, login } = Mailchimp.Meta;
        const { avatar, login_name } = login || {};
        const DashboardUrl = `https://${dc}.admin.mailchimp.com`;
        const CampaignsUrl = `https://${dc}.admin.mailchimp.com/campaigns`;

        return (

            <div className={ CS }>

                <Logo />

                <div className="HeaderRight">

                    <div className="HeaderUser">

                        <div className="HeaderUserAccount">{ accountname }</div>
                        <div className="HeaderUserLogin">{ login_name }</div>

                    </div>

                    <div className="HeaderAvatar" onClick={ this.OnToggle }>

                        <LoadImage src={ avatar } />

                    </div>

                    { expand ? (

                        <Sticky
                        
                            align="right"
                            className="HeaderMenu"
                            onClose={ this.OnToggle }
                        
                        >

                            <Link to="/">Mallar</Link>
                            <Link to="/media">Media</Link>
                            <Link href={ CampaignsUrl }>Kampanjer <Icon feather="ExternalLink" /></Link>
                            <Link href={ DashboardUrl }>Dashboard <Icon feather="ExternalLink" /></Link>
                            <Item onClick={ Mailchimp.Logout } >Logga ut</Item>

                        </Sticky>

                    ) : "" }

                </div>
                
            </div>

        );

    }
    
}

export default Header;