
/**!
 *  Template modules interface
 *
 *  @prop string className - Append a class name.
 * 
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./modules.scss";

import Parser from "Class/Parser";

import Button from "Components/UI/Button";
import ListField from "Components/UI/Field/ListField";
import Sticky from "Components/Layout/Sticky";

class Modules extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            expand: false

        }

    }

    Add = ( e, type ) => {

        const { id, onAdd } = this.props;

        onAdd( e, type, id );

        this.setState( {

            expand: false

        } );

    }

    ItemModule = ( type, id ) => {

        const { templates } = this.props;
        const { name, svg } = templates[ type ] || {};

        if ( !name || !svg ) {

            return;

        }

        const Preview = atob( svg );

        return (

            <div
            
                className="ModulesItem"
                key={ id }
            
            >{ Parser.Parse( Preview ) }</div>

        );

    }

    ItemTemplate = ( name, type ) => {

        return (

            <div
            
                className="ModulesAddItem"
                key={ type }
                onClick={ e => this.Add( e, type ) }
                
            >{ name }</div>

        );

    }

    OnEdit = ( e, index, switchTab = true ) => {

        const { id, onEdit } = this.props;

        onEdit( e, index, switchTab, id );

    }

    OnRemove = ( e, index ) => {

        const { id, onRemove } = this.props;

        onRemove( e, index, id );

    }

    OnSort = ( e, from, to ) => {

        const { id, onSort } = this.props;

        onSort( e, from, to, id );

    }

    OnToggle = () => {

        const { expand } = this.state;

        this.setState( { expand: !expand } );

    }

    render() {

        const { active, className, disabled, label, modules, templates } = this.props;
        const { expand } = this.state;
        const CA = [ "Modules" ];

        if ( className ) {

            CA.push( className );

        }

        const CS = CA.join( " " );
        const Modules = [];
        const Templates = [];
        const Ready = typeof modules === "object" && typeof templates === "object";

        if ( Ready ) {

            modules.forEach( module => {

                let { id, type } = module;

                Modules.push( this.ItemModule( type, id ) );

            } );

            for ( let slug in templates ) {

                let { name, repeatable } = templates[ slug ];

                if ( !repeatable ) {

                    continue;

                }

                Templates.push( this.ItemTemplate( name, slug ) );

            }

        }

        return (

            <div className={ CS }>

                <ListField
                
                    className="ModulesItems"
                    disabled={ disabled }
                    highlight={ active }
                    label={ label }
                    lock={[ 0, Modules.length - 1 ]}
                    onEdit={ ( e, index, id, switchTab ) => this.OnEdit( e, index, switchTab ) }
                    onRemove={ this.OnRemove }
                    onSort={ this.OnSort }
                    onSortStart={ ( e, index ) => this.OnEdit( e, index, false ) }
                    value={ Modules }
                
                />

                <div className="ModulesAdd">

                    <Button

                        disabled={ !Templates.length || disabled }
                        feather="Plus"
                        label="Ny modul..."
                        onClick={ this.OnToggle }
                    
                    />

                    { expand ? <Sticky
                    
                        align="center"
                        className="ModulesAddMenu"
                        flip={ true }
                        onClose={ this.OnToggle }
                    
                    >

                        { Templates }

                    </Sticky> : "" }

                </div>

            </div>

        );

    }

}

Modules.propTypes = {

    active: PropTypes.number,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    modules: PropTypes.oneOfType( [ PropTypes.array, PropTypes.bool ] ),
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func,
    onSort: PropTypes.func,
    templates: PropTypes.oneOfType( [ PropTypes.object, PropTypes.bool ] )

};

Modules.defaultProps = {

    active: -1,
    className: "",
    disabled: false,
    id: "",
    label: "",
    modules: [],
    onAdd: () => {},
    onEdit: () => {},
    onRemove: () => {},
    onSort: () => {},
    templates: {}

};

export default Modules;
