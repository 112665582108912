
/**!
 *  Progress bar.
 *
 *  @prop string className - Append a class name.
 *  @prop boolean full - Whether the progress should be 100% width.
 *  @prop boolean percent - Whether to show percent completed in the left label.
 *  @prop float progress - Progress 0-1.
 *  @prop string leftLabel - Label to the left of the progress bar.
 *  @prop string rightLabel - Label to the right of the progress bar.
 *
 *  Author: Bjorn Tollstrom <bjorn@rodolfo.se>
 */

import React from "react";
import PropTypes from "prop-types";
import "./progress.scss";

class Progress extends React.Component {

    render() {

        const { className, full, leftLabel, percent, progress, rightLabel } = this.props;
        const CA = [ "Progress" ];

        if ( className ) CA.push( className );
        if ( full ) CA.push( "Full" );

        let LeftLabel = leftLabel;

        if ( !LeftLabel && percent && !full ) {

            LeftLabel = Math.round( progress * 100 ) + "%";

        }

        const CS = CA.join( " " );

        return (

            <div className={ CS }>

                <div className="ProgressLeft">

                    { LeftLabel }

                </div>

                <div className="ProgressRight">

                    { rightLabel }

                </div>

                <div className="ProgressBar">

                    <div className="ProgressBarFill" style={{ width: ( progress * 100 ) + "%" }} />

                </div>

            </div>

        );

    }

}

Progress.propTypes = {

    className: PropTypes.string,
    full: PropTypes.bool,
    percent: PropTypes.bool,
    progress: PropTypes.number,
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string

};

Progress.defaultProps = {

    className: "",
    full: false,
    percent: true,
    progress: 0,
    leftLabel: "",
    rightLabel: "",

}

export default Progress;