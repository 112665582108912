
import React from "react";
import "./auth.scss";

import Mailchimp from "Class/Mailchimp";
import { Redirect } from "react-router-dom";

import Button from "Components/UI/Button";
import Center from "Components/Layout/Center";
import Logo from "Components/Layout/Logo";
import Spinner from "Components/Feedback/Spinner";

class ViewAuth extends React.Component {

    constructor( props ) {

        super( props );

        this.state = {

            authed: false,
            error: false,
            loading: false

        };

    }

    componentDidMount() {

        const { location } = this.props; 
        const Query = location ? location.search.match( /code=([0-9a-f]*)/ ) : false;
        const Code = Query ? Query[1] : false;

        if ( Mailchimp.Authed() ) {

            this.Authed();

        }

        else if ( Code ) {

            this.setState( { loading: true } );

            Mailchimp.AuthByCode( Code, authed => {

                if ( !authed ) {

                    this.setState( {

                        error: true,
                        loading: false

                    } );

                }

                else {

                    this.Authed();

                }

            } );

        }

    }

    Authed = () => {

        this.setState( {

            authed: true

        } );

    }

    render() {

        const { authed, error, loading } = this.state;
        const CA = [ "Auth" ];
        let Content = "";

        if ( authed ) {

            return <Redirect to="/" />;

        }

        else if ( error ) {

            CA.push( "Error" );

            Content = (

                <div className="AuthContainer">

                    <Logo />

                    <div className="AuthContainerText">

                        <h1>Inloggningen misslyckades</h1>

                        <p>Det kan vara problem med anslutningen till Mailchimp eller så har du försökt att logga in med ett konto som inte har tillgång till Mailchimp Editor. Vänta en stund och försök sedan igen. Om problemet kvarstår, kontakta <a href="mailto:bjorn@rodolfo.se">bjorn@rodolfo.se</a></p>

                    </div>

                    <Button
                    
                        to="/"
                        label="Försök igen"
                    
                    />

                </div>

            );

        }

        else if ( loading ) {

            CA.push( "Loading" );

            Content = <Spinner />;

        }

        else {

            Content = (

                <div className="AuthContainer">

                    <Logo />

                    <div className="AuthContainerText">

                        <h1>Logga in med Mailchimp</h1>

                        <p>För att få åtkomst till Mailchimp Editor så måste du logga in med ditt Mailchimp-konto. I nuläget så har endast Bon Relations konto åtkomst. Tryck på knappen för att påbörja inloggningen.</p>

                    </div>

                    <Button
                    
                        href={ Mailchimp.AuthUrl() }
                        label="Logga in"
                        target="_self"
                    
                    />

                </div>

            );

        }

        const CS = CA.join( " " );

        return (

            <Center className={ CS }>{ Content }</Center>

        );

    }

}

export default ViewAuth;